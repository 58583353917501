//
// o-audience-cards.scss
//
//

.o-audience-cards {

  font-size: 1.25vw;

  @media (min-width: bp-width-min(md)) {
    font-size: .7vw;
  }

  @media (min-width: bp-width-min(lg)) {
    font-size: .85vw;
  }

  @media (min-width: bp-width-min(xxl)) {
    font-size: 14px;
  }
}

.o-audience-cards__item {
  transition: 
    transform 3s $easing-fastInSlowOut;

  .js .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}

.o-audience-cards__item--1 {

  .js & {
    transform: translate3d(10em,0,0);
  }

}

.o-audience-cards__item--2 {

  .js & {
    transform: translate3d(15em,0,0);
  }

}

.o-audience-cards__item--3 {

  .js & {
    transform: translate3d(20em,0,0);
  }

}