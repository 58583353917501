//
// c-insights-dash.scss
//
//

.c-insights-dash {
  position: relative;

  width: 10em;

  margin-left: auto;
  margin-right: auto;

  font-size: 8vw;

  @media (min-width: bp-width-min(lg)) {
    font-size: 7vw;
  }

  @media (min-width: bp-width-min(xxl)) {
    font-size: 7rem;
  }
}


// Main image container
// ------------------------------------------

.c-insights-dash__main-img {
  transition: transform 2s $easing-fastInSlowOut;

  width: 100%;

  z-index: 1;

  .js & {
    transform: translate3d(0,.25em,0);
  }

  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}


// Dashboards over
// ------------------------------------------

.c-insights-dash__dashboards {
  transition: transform 2s $easing-fastInSlowOut;

  position: absolute;

  width: 1.4em;
  
  left: -.098em;
  top: 1.15em;

  z-index: 2;

  .js & {
    transform: translate3d(0,.6em,0);
  }

  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}


// Unique images
// ------------------------------------------

.c-insights-dash__unique {
  transition: transform 2s $easing-fastInSlowOut;

  position: absolute;

  width: 1.9em;
  
  left: 2.75em;
  top: 1.8em;

  z-index: 2;

  .js & {
    transform: translate3d(0,.6em,0);
  }

  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}


// Card
// ------------------------------------------
.c-insights-dash__card {
  transition: transform 2s $easing-fastInSlowOut;

  position: absolute;

  width: 1.6em;

  bottom: -.4em;
  right: -.23em;

  z-index: 2;

  .js & {
    transform: translate3d(0,.6em,0);
  }

  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}
