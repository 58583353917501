// 
// c-product-nav.scss
// 
//

.c-product-nav {
  transition: transform 1.5s $easing-fastInSlowOut;

  position: relative;

  display: flex;

  align-content: center;

  background-color: $color-white;

  border-radius: $border-radius-ui;

  padding: map-get($layout-scale-rem, 3);

  flex: 1;

  @media (min-width: bp-width-min(lg)) {
    padding: map-get($layout-scale-rem, 4);
  }

  // It's more performant to animate two elements via apacity than one via box-shadow
  &:after,
  &:before {
    transition: opacity 1.5s $easing-fastInSlowOut;

    content: "";

    border-radius: $border-radius-ui;

    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  &:before {
    z-index: 1;

    box-shadow: map-get($shadow-depth, 1);

    opacity: 1;
  }

  &:after {
    z-index: 1;

    box-shadow: map-get($shadow-depth, 3);

    opacity: 0;
  }

  &:hover {
    transform: translate3d(0,-.25rem,0);

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }

}

.c-product-nav__content {
  position: relative;

  width: 100%;

  z-index: 2;

  display: flex;

  align-items: center;
}

.c-product-nav__icon {
  margin-right: map-get($layout-scale-rem, 3);
  max-width: 4rem;

  width: 100%;

  @media (min-width: bp-width-min(lg)) {
    margin-right: map-get($layout-scale-rem, 4);
    max-width: 5rem;
  }
}

.c-product-nav__details {
  flex: 1;
}