//
// animations.scss
//
//


// APAC
// ------------------------------------------

@keyframes apac-pulse {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes apac-show-element {
  0% {
    transform: translate3d(0,3rem,0);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}


// Image Insights
// ------------------------------------------

@keyframes insights-pulse-simple {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(4);
  } 
}

@keyframes insights-card-fade-in {
  0% {
    opacity: 0;
    transform: translate3d(0,-1em,0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  } 
}

@keyframes insights-card-col-down {
  0% {
    transform: translate3d(0,0,0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0,10em,0);
  } 
}

@keyframes insights-text-opening {
  0% {
    transform: translate3d(0,1em,0);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}


// Analytics
// ------------------------------------------

@keyframes analytics-dashboard-slide {
  0% {
    transform: translate3d(0,.025em,0) scale(1.05);
  }

  100% {
    transform: translate3d(0,0,0) scale(1);
  }
}

@keyframes analytics-texture-in {
  0% {
    transform: scale(.95);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

