// 
// o-insights-card.scss
//

.o-insights-card {
  font-size: 1em;

  width: 18em;

  margin: auto;

  background-color: $color-white;
}

.o-insights-card__img {
  position: relative;
  display: block;
}

.o-insights-card__img__bg {
  position: absolute;

  width: 100%;
  height: 100%;

  left: 0;
  top: 0;

  background-position: center;
  background-size: cover;

  background-color: $color-white;
}

.o-insights-card__details {
  padding: .9em 1.5em .5em 1.5em;
}

.o-insights-card__details__row {
  display: flex;
  justify-content: space-between;

  padding: .6em 0;

  border-bottom: 1px dotted map-get($color-grey, 5);

  letter-spacing: .01em;
  line-height: 1;
}

.o-insights-card__logos {
  display: flex;
  flex-wrap: wrap;

  padding: 1em 0;
}

.o-insights-card__logo-item {
  max-height: 1.5em;
  max-width: 4em;
  align-self: center;
  opacity: 0.8;
}