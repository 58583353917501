@media (width <= 29.9375em) {
  .x-non-desktop-home-heading *, .x-platform-hero-heading {
    font-size: 16vw !important;
  }

  .is-es-site .x-non-desktop-home-heading *, .is-es-site .x-platform-hero-heading {
    font-size: 14.5vw !important;
  }

  .is-fr-site .x-non-desktop-home-heading *, .is-fr-site .x-platform-hero-heading {
    font-size: 13vw !important;
  }

  .x-non-desktop-home-heading * {
    line-height: .88 !important;
  }
}

@media (width >= 75em) {
  .x-new-markets-block {
    margin-top: -9rem;
  }
}

.x-home-hero-controls {
  bottom: 24px;
  left: 50%;
}

.x-home-results-controls {
  bottom: -16rem;
}

@media (width >= 56.25em) {
  .x-home-results-controls {
    bottom: -2.4rem;
    left: 2.5rem;
  }
}

@media (width >= 75em) {
  .x-home-results-controls {
    bottom: -3rem;
    left: 50%;
  }
}

.x-home-results-controls-arrows {
  bottom: -8rem;
}

@media (width >= 56.25em) {
  .x-home-results-controls-arrows {
    bottom: -3rem;
  }
}

.u-show-scroll {
  opacity: 0;
  transition: transform 1.2s cubic-bezier(.075, .82, .165, 1), opacity .3s linear;
  transform: translate3d(0, 30%, 0);
}

.s-data-section-scrollfx .u-show-scroll {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@keyframes apac-pulse {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes apac-show-element {
  0% {
    opacity: 0;
    transform: translate3d(0, 3rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes insights-pulse-simple {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(4);
  }
}

@keyframes insights-card-fade-in {
  0% {
    opacity: 0;
    transform: translate3d(0, -1em, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes insights-card-col-down {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 10em, 0);
  }
}

@keyframes insights-text-opening {
  0% {
    opacity: 0;
    transform: translate3d(0, 1em, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes analytics-dashboard-slide {
  0% {
    transform: translate3d(0, .025em, 0)scale(1.05);
  }

  100% {
    transform: translate3d(0, 0, 0)scale(1);
  }
}

@keyframes analytics-texture-in {
  0% {
    opacity: 0;
    transform: scale(.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.o-globe-hero {
  position: relative;
  overflow: hidden;
}

.o-globe-hero__globe {
  height: 50vh;
  min-height: 8rem;
  max-height: 9rem;
  font-size: 90vw;
  display: block;
  position: relative;
}

@media (width >= 30em) {
  .o-globe-hero__globe {
    height: 60vh;
    min-height: 11rem;
    max-height: 12rem;
  }
}

@media (width >= 37.5em) {
  .o-globe-hero__globe {
    min-height: 14rem;
    max-height: 18rem;
  }
}

@media (width >= 56.25em) {
  .o-globe-hero__globe {
    min-height: 18rem;
    max-height: 26rem;
  }
}

@media (width >= 75em) {
  .o-globe-hero__globe {
    min-height: 15rem;
    max-height: 22rem;
    font-size: 70vw;
  }
}

@media (width >= 115.625em) {
  .o-globe-hero__globe {
    min-height: 20rem;
    max-height: 26rem;
  }
}

@media (width >= 140.625em) {
  .o-globe-hero__globe {
    min-height: 20rem;
    max-height: 44rem;
    font-size: 90rem;
  }
}

.o-globe-hero__sub {
  animation-name: apac-show-element;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(.075, .82, .165, 1);
  animation-fill-mode: forwards;
}

.o-globe-hero__title {
  opacity: 0;
  animation-name: apac-show-element;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(.075, .82, .165, 1);
  animation-delay: .2s;
  animation-fill-mode: forwards;
}

.o-globe-hero__primary-btn {
  opacity: 0;
  animation-name: apac-show-element;
  animation-duration: 2.5s;
  animation-timing-function: cubic-bezier(.075, .82, .165, 1);
  animation-delay: .3s;
  animation-fill-mode: forwards;
}

.o-globe-hero__secondary-btn {
  opacity: 0;
  animation-name: apac-show-element;
  animation-duration: 2.5s;
  animation-timing-function: cubic-bezier(.075, .82, .165, 1);
  animation-delay: .4s;
  animation-fill-mode: forwards;
}

.o-globe-hero__globe__container {
  width: 1em;
  height: 1em;
  margin-left: -.5em;
  position: absolute;
  top: 0;
  left: 50%;
}

.o-globe-hero__globe__land {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -.003em;
  left: 0;
}

.o-globe-hero__globe__pulses {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.o-globe-hero__globe__pulse {
  border-radius: 50%;
  width: .02em;
  height: .02em;
  position: absolute;
}

.o-globe-hero__globe__pulse__inner {
  border-radius: 50%;
  width: .8em;
  height: .8em;
  font-size: .02em;
  position: relative;
}

.o-globe-hero__globe__pulse__inner:before {
  border: 1px solid #868690;
}

.o-globe-hero__globe__pulse__inner:after, .o-globe-hero__globe__pulse__inner:before {
  content: "";
  border-radius: 50%;
  width: 1em;
  height: 1em;
  margin-top: -.5em;
  margin-left: -.5em;
  font-size: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
}

.o-globe-hero__globe__pulse--fox {
  top: .0225em;
  left: .375em;
  transform: perspective(25rem)rotateX(66deg)rotateY(-14deg);
}

.o-globe-hero__globe__pulse--fox .o-globe-hero__globe__pulse__inner {
  background-color: #8ac539;
}

.o-globe-hero__globe__pulse--fox .o-globe-hero__globe__pulse__inner:after {
  background-color: #8ac539;
  font-size: 10em;
  animation-name: apac-pulse;
  animation-duration: 8s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  transform: scale(0);
}

.o-globe-hero__globe__pulse--fox .o-globe-hero__globe__pulse__inner:before {
  opacity: .4;
  background-color: #8ac539;
  font-size: 9em;
}

.o-globe-hero__globe__pulse--panther {
  top: .14em;
  left: .29em;
  transform: perspective(25rem)rotateX(50deg)rotateY(-16deg);
}

.o-globe-hero__globe__pulse--panther .o-globe-hero__globe__pulse__inner {
  background-color: #53a3e5;
}

.o-globe-hero__globe__pulse--panther .o-globe-hero__globe__pulse__inner:after {
  background-color: #53a3e5;
  font-size: 8em;
  animation-name: apac-pulse;
  animation-duration: 8s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-delay: 3s;
  transform: scale(0);
}

.o-globe-hero__globe__pulse--panther .o-globe-hero__globe__pulse__inner:before {
  opacity: .4;
  background-color: #53a3e5;
  font-size: 8em;
  animation-delay: .25s;
}

.o-globe-hero__globe__pulse--dingo {
  top: .215em;
  left: .4em;
  transform: perspective(25rem)rotateX(40deg)rotateY(-11deg);
}

.o-globe-hero__globe__pulse--dingo .o-globe-hero__globe__pulse__inner:after {
  font-size: 6em;
  animation-name: apac-pulse;
  animation-duration: 8s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-delay: 5s;
  transform: scale(0);
}

.o-globe-hero__globe__pulse--dingo .o-globe-hero__globe__pulse__inner:before {
  opacity: .4;
  font-size: 6em;
  animation-delay: .5s;
}

.o-globe-hero__globe__land__img {
  width: 1em;
  height: 1em;
  display: block;
}

.o-globe-hero__globe__land__img__land {
  opacity: 1;
  fill: #eee;
  stroke: #e4e4e4;
  stroke-width: 1.5px;
  vector-effect: non-scaling-stroke;
}

.o-globe-hero__globe__body {
  background-color: #f9f9f9;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: inset 0 0 .002em #c6c7cc, 0 0 2em #fff;
}

.o-globe-hero__globe__body:after {
  content: "";
  opacity: .7;
  background: radial-gradient(#f9f9f9 0%, #eee0 60%);
  width: 1.5em;
  height: 1em;
  position: absolute;
  top: -.45em;
  left: -.25em;
}

@media (width <= 29.9375em) {
  .o-globe-hero__globe__angle {
    display: none;
  }
}

@media (width >= 37.5em) {
  .o-globe-hero__globe__angle {
    opacity: .4;
    background-color: #fff;
    border-top: 1px solid #c6c7cc;
    width: 200%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotate(-3deg);
  }
}

.o-sloped-section {
  position: relative;
  box-shadow: 0 10px 40px #25232817, 0 7px 25px #2523280d;
}

@media (width >= 37.5em) {
  .o-sloped-section {
    box-shadow: none;
    margin-top: -15vw;
    padding-top: 12vw;
  }
}

@media (width >= 56.25em) {
  .o-sloped-section {
    padding-top: 15vw;
  }
}

.o-sloped-section__slope {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 37.5em) {
  .o-sloped-section__slope {
    height: 14vw;
  }

  .o-sloped-section__slope__content {
    transition: transform 1s cubic-bezier(.075, .82, .165, 1);
    transform: translate3d(0, 0, 0);
  }

  .s-data-section-scrollfx .o-sloped-section__slope__content {
    transform: translate3d(0, -11%, 0);
  }
}

.o-sloped-section__slope__angle {
  width: 200%;
  height: 100%;
  position: absolute;
}

@media (width >= 37.5em) {
  .o-sloped-section__slope__angle {
    height: 1000%;
    transition: transform .75s cubic-bezier(.075, .82, .165, 1);
    left: 50%;
    transform: translate3d(-50%, 8vw, 0)rotate(7deg);
  }

  .s-data-section-scrollfx .o-sloped-section__slope__angle {
    transform: translateX(-50%)rotate(0);
  }
}

.o-sloped-section__content {
  padding-top: 3rem;
}

@media (width >= 37.5em) {
  .o-sloped-section__content {
    margin-top: 3rem;
    padding-top: 0;
  }
}

@media (width >= 56.25em) {
  .o-sloped-section__content {
    margin-top: 1.5rem;
  }
}

@media (width >= 75em) {
  .o-sloped-section__content {
    margin-top: -4vw;
  }
}

.o-crawl {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.o-crawl__ticker {
  opacity: 0;
  position: relative;
  transform: translate3d(0, 50%, 0);
}

.o-crawl__ticker.s-crawl__ticker-visible {
  opacity: 1;
  transition: opacity 2s linear, transform 3s cubic-bezier(.075, .82, .165, 1);
  transform: translate3d(0, 0, 0);
}

.o-crawl__ticker__reserve {
  width: 5em;
  height: 1.5em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.o-crawl__ticker__pos {
  width: 100%;
  padding-bottom: .5em;
  padding-left: .15em;
  padding-right: .15em;
  position: absolute;
  top: 0;
  left: 0;
}

.o-crawl__ticker__number {
  height: 1em;
  line-height: 1;
}

.o-crawl__ticker__number span {
  opacity: 0;
  display: inline-block;
  transform: translate3d(0, 200%, 0);
}

.s-crawl__ticker-visible .o-crawl__ticker__number span:first-child {
  transition-delay: 50ms;
}

.s-crawl__ticker-visible .o-crawl__ticker__number span:nth-child(2) {
  transition-delay: .1s;
}

.s-crawl__ticker-visible .o-crawl__ticker__number span:nth-child(3) {
  transition-delay: .15s;
}

.s-crawl__ticker-visible .o-crawl__ticker__number span:nth-child(4) {
  transition-delay: .2s;
}

.s-crawl__ticker-visible .o-crawl__ticker__number span:nth-child(5) {
  transition-delay: .25s;
}

.s-crawl__ticker-visible .o-crawl__ticker__number span:nth-child(6) {
  transition-delay: .3s;
}

.s-crawl__ticker-visible .o-crawl__ticker__number span:nth-child(7) {
  transition-delay: .35s;
}

.s-crawl__ticker-visible .o-crawl__ticker__number span:nth-child(8) {
  transition-delay: .4s;
}

.s-crawl__ticker-visible .o-crawl__ticker__number span:nth-child(9) {
  transition-delay: .45s;
}

.s-crawl__ticker-visible .o-crawl__ticker__number span:nth-child(10) {
  transition-delay: .5s;
}

.s-crawl__ticker-visible .o-crawl__ticker__number span {
  opacity: 1;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1), opacity .5s linear;
  transform: translate3d(0, 0, 0);
}

.o-crawl__ticker__label {
  border-bottom: 1px solid #868690;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.o-crawl__ticker__label:after, .o-crawl__ticker__label:before {
  content: "";
  background-color: #868690;
  width: 1px;
  height: .2em;
  position: absolute;
  bottom: 0;
}

.o-crawl__ticker__label:after {
  left: 0;
}

.o-crawl__ticker__label:before {
  right: 0;
}

.o-crawl__ticker__label__text {
  background-color: #fcfcfd;
  padding-left: 3em;
  padding-right: 3em;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.o-section-logos-item {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.o-section-logos-item svg {
  opacity: 1;
  fill: #868690;
  width: 100%;
  max-width: 10rem;
  max-height: 2rem;
  display: block;
}

@media (width >= 56.25em) {
  .o-section-logos-item svg {
    max-width: 10rem;
    max-height: 3rem;
  }
}

.o-insights-card {
  background-color: #fff;
  width: 18em;
  margin: auto;
  font-size: 1em;
}

.o-insights-card__img {
  display: block;
  position: relative;
}

.o-insights-card__img__bg {
  background-color: #fff;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.o-insights-card__details {
  padding: .9em 1.5em .5em;
}

.o-insights-card__details__row {
  letter-spacing: .01em;
  border-bottom: 1px dotted #868690;
  justify-content: space-between;
  padding: .6em 0;
  line-height: 1;
  display: flex;
}

.o-insights-card__logos {
  flex-wrap: wrap;
  padding: 1em 0;
  display: flex;
}

.o-insights-card__logo-item {
  opacity: .8;
  align-self: center;
  max-width: 4em;
  max-height: 1.5em;
}

.o-insights-grid {
  flex-direction: row;
  justify-content: center;
  width: 300em;
  font-size: 8px;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (width >= 37.5em) {
  .o-insights-grid {
    font-size: 9px;
  }
}

@media (width >= 56.25em) {
  .o-insights-grid {
    font-size: 10px;
  }
}

@media (width >= 75em) {
  .o-insights-grid {
    font-size: 12px;
  }
}

.o-insights-grid__column {
  margin-top: -10em;
  padding: 1em;
  animation-name: insights-card-col-down;
  animation-duration: 30s;
  animation-fill-mode: forwards;
}

.o-insights-grid__item {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-bottom: 2em;
  overflow: hidden;
  box-shadow: 0 7px 30px #25232818, 0 4px 20px #2523280d;
}

.o-insights-grid__filler {
  background-color: #fff;
  margin-bottom: 2em;
  box-shadow: 0 7px 30px #25232818, 0 4px 20px #2523280d;
}

.o-insights-grid__filler--tall {
  height: 38em;
}

.o-insights-grid__filler--medium {
  height: 23em;
}

.o-insights-grid__filler--small {
  height: 8em;
}

.o-insights-grid__filler--1 {
  height: 10em;
}

.o-insights-grid__filler--2 {
  height: 20em;
}

.o-insights-grid__filler--3 {
  height: 30em;
}

.o-insights-grid__filler--4 {
  height: 40em;
}

.o-insights-grid__filler--5 {
  height: 50em;
}

.o-insights-grid__fade {
  opacity: 0;
  animation-name: insights-card-fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.o-insights-grid__column:nth-child(0) .o-insights-grid__fade:nth-child(0) {
  animation-delay: 0s;
}

.o-insights-grid__column:nth-child(0) .o-insights-grid__fade:first-child {
  animation-delay: .1s;
}

.o-insights-grid__column:nth-child(0) .o-insights-grid__fade:nth-child(2) {
  animation-delay: .2s;
}

.o-insights-grid__column:nth-child(0) .o-insights-grid__fade:nth-child(3) {
  animation-delay: .3s;
}

.o-insights-grid__column:nth-child(0) .o-insights-grid__fade:nth-child(4) {
  animation-delay: .4s;
}

.o-insights-grid__column:nth-child(0) .o-insights-grid__fade:nth-child(5) {
  animation-delay: .5s;
}

.o-insights-grid__column:nth-child(0) .o-insights-grid__fade:nth-child(6) {
  animation-delay: .6s;
}

.o-insights-grid__column:nth-child(0) .o-insights-grid__fade:nth-child(7) {
  animation-delay: .7s;
}

.o-insights-grid__column:nth-child(0) .o-insights-grid__fade:nth-child(8) {
  animation-delay: .8s;
}

.o-insights-grid__column:nth-child(0) .o-insights-grid__fade:nth-child(9) {
  animation-delay: .9s;
}

.o-insights-grid__column:first-child .o-insights-grid__fade:nth-child(0) {
  animation-delay: .2s;
}

.o-insights-grid__column:first-child .o-insights-grid__fade:first-child {
  animation-delay: .3s;
}

.o-insights-grid__column:first-child .o-insights-grid__fade:nth-child(2) {
  animation-delay: .4s;
}

.o-insights-grid__column:first-child .o-insights-grid__fade:nth-child(3) {
  animation-delay: .5s;
}

.o-insights-grid__column:first-child .o-insights-grid__fade:nth-child(4) {
  animation-delay: .6s;
}

.o-insights-grid__column:first-child .o-insights-grid__fade:nth-child(5) {
  animation-delay: .7s;
}

.o-insights-grid__column:first-child .o-insights-grid__fade:nth-child(6) {
  animation-delay: .8s;
}

.o-insights-grid__column:first-child .o-insights-grid__fade:nth-child(7) {
  animation-delay: .9s;
}

.o-insights-grid__column:first-child .o-insights-grid__fade:nth-child(8) {
  animation-delay: 1s;
}

.o-insights-grid__column:first-child .o-insights-grid__fade:nth-child(9) {
  animation-delay: 1.1s;
}

.o-insights-grid__column:nth-child(2) .o-insights-grid__fade:nth-child(0) {
  animation-delay: .4s;
}

.o-insights-grid__column:nth-child(2) .o-insights-grid__fade:first-child {
  animation-delay: .5s;
}

.o-insights-grid__column:nth-child(2) .o-insights-grid__fade:nth-child(2) {
  animation-delay: .6s;
}

.o-insights-grid__column:nth-child(2) .o-insights-grid__fade:nth-child(3) {
  animation-delay: .7s;
}

.o-insights-grid__column:nth-child(2) .o-insights-grid__fade:nth-child(4) {
  animation-delay: .8s;
}

.o-insights-grid__column:nth-child(2) .o-insights-grid__fade:nth-child(5) {
  animation-delay: .9s;
}

.o-insights-grid__column:nth-child(2) .o-insights-grid__fade:nth-child(6) {
  animation-delay: 1s;
}

.o-insights-grid__column:nth-child(2) .o-insights-grid__fade:nth-child(7) {
  animation-delay: 1.1s;
}

.o-insights-grid__column:nth-child(2) .o-insights-grid__fade:nth-child(8) {
  animation-delay: 1.2s;
}

.o-insights-grid__column:nth-child(2) .o-insights-grid__fade:nth-child(9) {
  animation-delay: 1.3s;
}

.o-insights-grid__column:nth-child(3) .o-insights-grid__fade:nth-child(0) {
  animation-delay: .6s;
}

.o-insights-grid__column:nth-child(3) .o-insights-grid__fade:first-child {
  animation-delay: .7s;
}

.o-insights-grid__column:nth-child(3) .o-insights-grid__fade:nth-child(2) {
  animation-delay: .8s;
}

.o-insights-grid__column:nth-child(3) .o-insights-grid__fade:nth-child(3) {
  animation-delay: .9s;
}

.o-insights-grid__column:nth-child(3) .o-insights-grid__fade:nth-child(4) {
  animation-delay: 1s;
}

.o-insights-grid__column:nth-child(3) .o-insights-grid__fade:nth-child(5) {
  animation-delay: 1.1s;
}

.o-insights-grid__column:nth-child(3) .o-insights-grid__fade:nth-child(6) {
  animation-delay: 1.2s;
}

.o-insights-grid__column:nth-child(3) .o-insights-grid__fade:nth-child(7) {
  animation-delay: 1.3s;
}

.o-insights-grid__column:nth-child(3) .o-insights-grid__fade:nth-child(8) {
  animation-delay: 1.4s;
}

.o-insights-grid__column:nth-child(3) .o-insights-grid__fade:nth-child(9) {
  animation-delay: 1.5s;
}

.o-insights-grid__column:nth-child(4) .o-insights-grid__fade:nth-child(0) {
  animation-delay: .8s;
}

.o-insights-grid__column:nth-child(4) .o-insights-grid__fade:first-child {
  animation-delay: .9s;
}

.o-insights-grid__column:nth-child(4) .o-insights-grid__fade:nth-child(2) {
  animation-delay: 1s;
}

.o-insights-grid__column:nth-child(4) .o-insights-grid__fade:nth-child(3) {
  animation-delay: 1.1s;
}

.o-insights-grid__column:nth-child(4) .o-insights-grid__fade:nth-child(4) {
  animation-delay: 1.2s;
}

.o-insights-grid__column:nth-child(4) .o-insights-grid__fade:nth-child(5) {
  animation-delay: 1.3s;
}

.o-insights-grid__column:nth-child(4) .o-insights-grid__fade:nth-child(6) {
  animation-delay: 1.4s;
}

.o-insights-grid__column:nth-child(4) .o-insights-grid__fade:nth-child(7) {
  animation-delay: 1.5s;
}

.o-insights-grid__column:nth-child(4) .o-insights-grid__fade:nth-child(8) {
  animation-delay: 1.6s;
}

.o-insights-grid__column:nth-child(4) .o-insights-grid__fade:nth-child(9) {
  animation-delay: 1.7s;
}

.o-insights-grid__column:nth-child(5) .o-insights-grid__fade:nth-child(0) {
  animation-delay: 1s;
}

.o-insights-grid__column:nth-child(5) .o-insights-grid__fade:first-child {
  animation-delay: 1.1s;
}

.o-insights-grid__column:nth-child(5) .o-insights-grid__fade:nth-child(2) {
  animation-delay: 1.2s;
}

.o-insights-grid__column:nth-child(5) .o-insights-grid__fade:nth-child(3) {
  animation-delay: 1.3s;
}

.o-insights-grid__column:nth-child(5) .o-insights-grid__fade:nth-child(4) {
  animation-delay: 1.4s;
}

.o-insights-grid__column:nth-child(5) .o-insights-grid__fade:nth-child(5) {
  animation-delay: 1.5s;
}

.o-insights-grid__column:nth-child(5) .o-insights-grid__fade:nth-child(6) {
  animation-delay: 1.6s;
}

.o-insights-grid__column:nth-child(5) .o-insights-grid__fade:nth-child(7) {
  animation-delay: 1.7s;
}

.o-insights-grid__column:nth-child(5) .o-insights-grid__fade:nth-child(8) {
  animation-delay: 1.8s;
}

.o-insights-grid__column:nth-child(5) .o-insights-grid__fade:nth-child(9) {
  animation-delay: 1.9s;
}

.o-insights-grid__column:nth-child(6) .o-insights-grid__fade:nth-child(0) {
  animation-delay: 1.2s;
}

.o-insights-grid__column:nth-child(6) .o-insights-grid__fade:first-child {
  animation-delay: 1.3s;
}

.o-insights-grid__column:nth-child(6) .o-insights-grid__fade:nth-child(2) {
  animation-delay: 1.4s;
}

.o-insights-grid__column:nth-child(6) .o-insights-grid__fade:nth-child(3) {
  animation-delay: 1.5s;
}

.o-insights-grid__column:nth-child(6) .o-insights-grid__fade:nth-child(4) {
  animation-delay: 1.6s;
}

.o-insights-grid__column:nth-child(6) .o-insights-grid__fade:nth-child(5) {
  animation-delay: 1.7s;
}

.o-insights-grid__column:nth-child(6) .o-insights-grid__fade:nth-child(6) {
  animation-delay: 1.8s;
}

.o-insights-grid__column:nth-child(6) .o-insights-grid__fade:nth-child(7) {
  animation-delay: 1.9s;
}

.o-insights-grid__column:nth-child(6) .o-insights-grid__fade:nth-child(8) {
  animation-delay: 2s;
}

.o-insights-grid__column:nth-child(6) .o-insights-grid__fade:nth-child(9) {
  animation-delay: 2.1s;
}

.o-insights-grid__column:nth-child(7) .o-insights-grid__fade:nth-child(0) {
  animation-delay: 1.4s;
}

.o-insights-grid__column:nth-child(7) .o-insights-grid__fade:first-child {
  animation-delay: 1.5s;
}

.o-insights-grid__column:nth-child(7) .o-insights-grid__fade:nth-child(2) {
  animation-delay: 1.6s;
}

.o-insights-grid__column:nth-child(7) .o-insights-grid__fade:nth-child(3) {
  animation-delay: 1.7s;
}

.o-insights-grid__column:nth-child(7) .o-insights-grid__fade:nth-child(4) {
  animation-delay: 1.8s;
}

.o-insights-grid__column:nth-child(7) .o-insights-grid__fade:nth-child(5) {
  animation-delay: 1.9s;
}

.o-insights-grid__column:nth-child(7) .o-insights-grid__fade:nth-child(6) {
  animation-delay: 2s;
}

.o-insights-grid__column:nth-child(7) .o-insights-grid__fade:nth-child(7) {
  animation-delay: 2.1s;
}

.o-insights-grid__column:nth-child(7) .o-insights-grid__fade:nth-child(8) {
  animation-delay: 2.2s;
}

.o-insights-grid__column:nth-child(7) .o-insights-grid__fade:nth-child(9) {
  animation-delay: 2.3s;
}

.o-insights-grid__column:nth-child(8) .o-insights-grid__fade:nth-child(0) {
  animation-delay: 1.6s;
}

.o-insights-grid__column:nth-child(8) .o-insights-grid__fade:first-child {
  animation-delay: 1.7s;
}

.o-insights-grid__column:nth-child(8) .o-insights-grid__fade:nth-child(2) {
  animation-delay: 1.8s;
}

.o-insights-grid__column:nth-child(8) .o-insights-grid__fade:nth-child(3) {
  animation-delay: 1.9s;
}

.o-insights-grid__column:nth-child(8) .o-insights-grid__fade:nth-child(4) {
  animation-delay: 2s;
}

.o-insights-grid__column:nth-child(8) .o-insights-grid__fade:nth-child(5) {
  animation-delay: 2.1s;
}

.o-insights-grid__column:nth-child(8) .o-insights-grid__fade:nth-child(6) {
  animation-delay: 2.2s;
}

.o-insights-grid__column:nth-child(8) .o-insights-grid__fade:nth-child(7) {
  animation-delay: 2.3s;
}

.o-insights-grid__column:nth-child(8) .o-insights-grid__fade:nth-child(8) {
  animation-delay: 2.4s;
}

.o-insights-grid__column:nth-child(8) .o-insights-grid__fade:nth-child(9) {
  animation-delay: 2.5s;
}

.o-insights-grid__column:nth-child(9) .o-insights-grid__fade:nth-child(0) {
  animation-delay: 1.8s;
}

.o-insights-grid__column:nth-child(9) .o-insights-grid__fade:first-child {
  animation-delay: 1.9s;
}

.o-insights-grid__column:nth-child(9) .o-insights-grid__fade:nth-child(2) {
  animation-delay: 2s;
}

.o-insights-grid__column:nth-child(9) .o-insights-grid__fade:nth-child(3) {
  animation-delay: 2.1s;
}

.o-insights-grid__column:nth-child(9) .o-insights-grid__fade:nth-child(4) {
  animation-delay: 2.2s;
}

.o-insights-grid__column:nth-child(9) .o-insights-grid__fade:nth-child(5) {
  animation-delay: 2.3s;
}

.o-insights-grid__column:nth-child(9) .o-insights-grid__fade:nth-child(6) {
  animation-delay: 2.4s;
}

.o-insights-grid__column:nth-child(9) .o-insights-grid__fade:nth-child(7) {
  animation-delay: 2.5s;
}

.o-insights-grid__column:nth-child(9) .o-insights-grid__fade:nth-child(8) {
  animation-delay: 2.6s;
}

.o-insights-grid__column:nth-child(9) .o-insights-grid__fade:nth-child(9) {
  animation-delay: 2.7s;
}

.o-insights-grid__column:nth-child(10) .o-insights-grid__fade:nth-child(0) {
  animation-delay: 2s;
}

.o-insights-grid__column:nth-child(10) .o-insights-grid__fade:first-child {
  animation-delay: 2.1s;
}

.o-insights-grid__column:nth-child(10) .o-insights-grid__fade:nth-child(2) {
  animation-delay: 2.2s;
}

.o-insights-grid__column:nth-child(10) .o-insights-grid__fade:nth-child(3) {
  animation-delay: 2.3s;
}

.o-insights-grid__column:nth-child(10) .o-insights-grid__fade:nth-child(4) {
  animation-delay: 2.4s;
}

.o-insights-grid__column:nth-child(10) .o-insights-grid__fade:nth-child(5) {
  animation-delay: 2.5s;
}

.o-insights-grid__column:nth-child(10) .o-insights-grid__fade:nth-child(6) {
  animation-delay: 2.6s;
}

.o-insights-grid__column:nth-child(10) .o-insights-grid__fade:nth-child(7) {
  animation-delay: 2.7s;
}

.o-insights-grid__column:nth-child(10) .o-insights-grid__fade:nth-child(8) {
  animation-delay: 2.8s;
}

.o-insights-grid__column:nth-child(10) .o-insights-grid__fade:nth-child(9) {
  animation-delay: 2.9s;
}

.o-audience-cards {
  font-size: 1.25vw;
}

@media (width >= 56.25em) {
  .o-audience-cards {
    font-size: .7vw;
  }
}

@media (width >= 75em) {
  .o-audience-cards {
    font-size: .85vw;
  }
}

@media (width >= 140.625em) {
  .o-audience-cards {
    font-size: 14px;
  }
}

.o-audience-cards__item {
  transition: transform 3s cubic-bezier(.075, .82, .165, 1);
}

.js .s-data-section-scrollfx .o-audience-cards__item {
  transform: translate3d(0, 0, 0);
}

.js .o-audience-cards__item--1 {
  transform: translate3d(10em, 0, 0);
}

.js .o-audience-cards__item--2 {
  transform: translate3d(15em, 0, 0);
}

.js .o-audience-cards__item--3 {
  transform: translate3d(20em, 0, 0);
}

.o-card-carousel {
  font-size: 1.25vw;
}

@media (width >= 56.25em) {
  .o-card-carousel {
    font-size: 1.1vw;
  }
}

@media (width >= 75em) {
  .o-card-carousel {
    font-size: 14px;
  }
}

.o-card-carousel__holder {
  width: 59em;
  height: 40em;
  margin: auto;
}

.o-card-carousel__col {
  width: 18em;
  margin-bottom: 4em;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: 0;
}

.js .s-data-section-scrollfx .o-card-carousel__col {
  transform: translate3d(0, 0, 0);
}

.o-card-carousel__col--1 {
  left: 0;
}

.js .o-card-carousel__col--1 {
  transform: translate3d(0, 3em, 0);
}

.o-card-carousel__col--2 {
  margin-left: -9em;
  left: 50%;
}

.js .o-card-carousel__col--2 {
  transform: translate3d(0, 6em, 0);
}

.o-card-carousel__col--3 {
  right: 0;
}

.js .o-card-carousel__col--3 {
  transform: translate3d(0, 9em, 0);
}

.o-card-carousel__card {
  z-index: 1;
  opacity: 0;
  transition: opacity .3s linear, transform .5s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: 1.1em;
  left: 0;
  transform: translate3d(0, 2em, 0);
}

.o-card-carousel__col--1 .o-card-carousel__card {
  transition-delay: 0s;
}

.o-card-carousel__col--2 .o-card-carousel__card {
  transition-delay: .1s;
}

.o-card-carousel__col--3 .o-card-carousel__card {
  transition-delay: .2s;
}

.o-card-carousel__card--visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.o-card-carousel__label {
  opacity: 0;
  z-index: 2;
  background-color: #fff;
  border-radius: 2px;
  padding: .6em .8em;
  font-size: 1.5em;
  line-height: 1;
  transition: opacity .3s linear, transform .5s cubic-bezier(.125, .98, .565, 1.17);
  display: inline-block;
  position: relative;
  transform: translate3d(0, 1em, 0);
  box-shadow: 0 .75px 6px #2523280d, 0 1px 1px #2523280d;
}

@media (width >= 30em) {
  .o-card-carousel__label {
    font-size: 1.1em;
  }
}

@media (width >= 56.25em) {
  .o-card-carousel__label {
    font-size: .8em;
  }
}

.o-card-carousel__col--1 .o-card-carousel__label {
  transition-delay: 0s;
}

.o-card-carousel__col--2 .o-card-carousel__label {
  transition-delay: .1s;
}

.o-card-carousel__col--3 .o-card-carousel__label {
  transition-delay: .2s;
}

.o-card-carousel__label--visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.o-card-carousel__nav {
  margin-bottom: 4em;
}

.o-card-carousel__nav__logo {
  width: 55%;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.8);
}

.o-card-carousel__nav__logo path, .o-card-carousel__nav__logo polygon {
  transition: fill .2s linear;
}

.o-card-carousel__nav__button:hover .o-card-carousel__nav__logo {
  transform: translate(-50%, -50%)scale(1.1);
}

.o-card-carousel__nav__button:active .o-card-carousel__nav__logo {
  transform: translate(-50%, -50%)scale(.9);
}

.o-card-carousel__nav__button--active .o-card-carousel__nav__logo {
  transform: translate(-50%, -50%)scale(1.1);
}

.o-card-carousel__nav__button--mono .o-card-carousel__nav__logo path, .o-card-carousel__nav__button--mono .o-card-carousel__nav__logo polygon {
  fill: #868690;
}

.o-card-carousel__nav__button {
  cursor: pointer;
  width: 9em;
  height: 4em;
  padding: 0;
  font-size: 1em;
  display: block;
  position: relative;
}

.o-card-carousel__nav__button:after, .o-card-carousel__nav__button:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.o-card-carousel__nav__button:after {
  opacity: 0;
  border: 2px solid #e60016;
  border-radius: 2px;
  transition: opacity .1s linear, transform 1s cubic-bezier(.075, .82, .165, 1);
  transform: scale(1.5);
}

.o-card-carousel__nav__button:before {
  opacity: 0;
  background-color: #e60016;
  transition: opacity .3s linear;
}

.o-card-carousel__nav__button--active:after {
  opacity: 1;
  transform: scale(1);
}

.o-card-carousel__nav__button--active:before {
  opacity: .05;
  transition-delay: .3s;
}

.c-logo-bubbles {
  width: 1em;
  height: 1em;
  font-size: 84vw;
  position: relative;
  top: -.15em;
  left: -.05em;
}

@media (width >= 37.5em) {
  .c-logo-bubbles {
    font-size: 38vw;
    top: -.05em;
  }
}

@media (width >= 140.625em) {
  .c-logo-bubbles {
    font-size: 40rem;
  }
}

.c-logo-bubbles__item {
  background-color: #f9f9f9;
  border: 1px solid #c6c7cc;
  border-radius: 50%;
  width: .5em;
  height: .5em;
  transition: transform 3s cubic-bezier(.075, .82, .165, 1), opacity 1s;
  position: absolute;
  overflow: hidden;
}

.js .c-logo-bubbles__item {
  opacity: 0;
  transform: scale(.5);
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item {
  opacity: 1;
}

.c-logo-bubbles__item svg {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-logo-bubbles__item .apac-logo-fill {
  fill: #51505a;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--1 {
  transition-delay: 1.125s;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--2 {
  transition-delay: 1s;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--3 {
  transition-delay: .875s;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--4 {
  transition-delay: .75s;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--5 {
  transition-delay: .625s;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--6 {
  transition-delay: .5s;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--7 {
  transition-delay: .375s;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--8 {
  transition-delay: .25s;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--9 {
  transition-delay: .125s;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--10 {
  transition-delay: 0s;
}

.c-logo-bubbles__item--1 {
  background-color: #53a3e5;
  border: 0;
  top: .04em;
  right: 0;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--1 {
  transform: scale(.95);
}

.c-logo-bubbles__item--1 .apac-logo-fill {
  fill: #fff;
}

.c-logo-bubbles__item--2 {
  bottom: 0;
  right: -.1em;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--2 {
  transform: scale(.78);
}

.c-logo-bubbles__item--3 {
  background-color: #8ac539;
  border: 0;
  bottom: 0;
  left: 0;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--3 {
  transform: scale(.65);
}

.c-logo-bubbles__item--3 .apac-logo-fill {
  fill: #fff;
}

.c-logo-bubbles__item--4 {
  border: 0;
  bottom: -.22em;
  right: .21em;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--4 {
  transform: scale(.5);
}

.c-logo-bubbles__item--4 .apac-logo-fill {
  fill: #fff;
}

.c-logo-bubbles__item--5 {
  bottom: .08em;
  right: .22em;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--5 {
  transform: scale(.4);
}

.c-logo-bubbles__item--6 {
  bottom: .27em;
  right: .3em;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--6 {
  transform: scale(.29);
}

.c-logo-bubbles__item--7 {
  top: .26em;
  left: .04em;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--7 {
  transform: scale(.22);
}

.c-logo-bubbles__item--8 {
  top: .09em;
  left: .13em;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--8 {
  transform: scale(.19);
}

.c-logo-bubbles__item--9 {
  top: .13em;
  left: .01em;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--9 {
  transform: scale(.14);
}

.c-logo-bubbles__item--10 {
  top: .01em;
  left: .02em;
}

.s-logo-bubbles-scrollfx .c-logo-bubbles__item--10 {
  transform: scale(.13);
}

.c-landing-foot {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.c-landing-foot__aux {
  flex: 1;
}

.c-landing-foot__aux__list {
  flex: 1;
  justify-content: flex-start;
  margin-top: -.8em;
  margin-bottom: -.8em;
  margin-left: -.8em;
  padding-left: 0;
  font-size: 1em;
  list-style: none;
  display: flex;
}

@media (width >= 30em) {
  .c-landing-foot__aux__list {
    margin-top: -1em;
    margin-bottom: -1em;
    margin-left: -1em;
  }
}

.c-landing-foot__aux__link {
  color: #51505a;
  padding: .8em;
  line-height: 1;
  transition: color .2s linear;
  display: block;
}

@media (width >= 30em) {
  .c-landing-foot__aux__link {
    padding: 1em;
  }
}

.c-landing-foot__aux__link:hover {
  color: #fff;
}

.c-landing-foot__aux__link:active {
  background-color: #0000;
}

.c-landing-foot__social__list {
  flex: 1;
  justify-content: flex-start;
  margin-top: -.5em;
  margin-bottom: -.5em;
  margin-right: -.5em;
  padding-left: 0;
  font-size: 1.1em;
  list-style: none;
  display: flex;
}

@media (width >= 30em) {
  .c-landing-foot__social__list {
    margin-top: -.6em;
    margin-bottom: -.6em;
    margin-right: -.6em;
    font-size: 1.3em;
  }
}

.c-landing-foot__social__link {
  color: #51505a;
  padding: .5em;
  transition: color .2s linear;
  display: block;
}

@media (width >= 30em) {
  .c-landing-foot__social__link {
    padding: .6em;
  }
}

.c-landing-foot__social__link:hover {
  color: #fff;
}

.c-landing-foot__social__link .c-icon, .c-landing-foot__social__icon {
  display: block;
}

.c-apac-frame {
  position: relative;
}

.c-apac-frame--language.s-frame-scrollfx .apac-map-full, .c-apac-frame--language.s-frame-scrollfx .apac-map-standard {
  transition: fill 1s .4s, stroke 1s .4s;
}

.c-apac-frame--language .apac-map-full {
  fill: #8ac539;
  stroke: #6e9d2e;
  stroke-width: 2px;
}

.js .c-apac-frame--language .apac-map-full {
  fill: #eee;
  stroke: #e1e1e1;
}

.c-apac-frame--language.s-frame-scrollfx .apac-map-full {
  fill: #8ac539;
  stroke: #6e9d2e;
}

.c-apac-frame--language .apac-map-standard {
  fill: #53a3e5;
  stroke: #3d97e2;
  stroke-width: 2px;
}

.js .c-apac-frame--language .apac-map-standard {
  fill: #eee;
  stroke: #e1e1e1;
}

.c-apac-frame--language.s-frame-scrollfx .apac-map-standard {
  fill: #53a3e5;
  stroke: #3d97e2;
}

.c-apac-frame--language .world {
  fill: #f9f9f9;
}

.c-apac-frame--data {
  position: relative;
}

.c-apac-frame--data .world {
  fill: #f9f9f9;
}

.c-apac-frame--data .asia-pac {
  fill: #eee;
  stroke: #d5d5d5;
  stroke-width: 2px;
}

.c-apac-frame__blip {
  z-index: 5;
  background-color: currentColor;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  font-size: .4em;
  transition: opacity .3s linear, transform .5s;
  position: absolute;
}

.js .c-apac-frame__blip {
  opacity: 0;
  transform: scale(0);
}

.s-frame-scrollfx .c-apac-frame__blip {
  opacity: 1;
  transform: scale(1);
}

.c-apac-frame__blip:after {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  margin-top: -.75em;
  margin-left: -.75em;
  font-size: 10vw;
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (width >= 37.5em) {
  .c-apac-frame__blip:after {
    font-size: 3vw;
  }
}

.js .c-apac-frame__blip:after {
  opacity: 0;
  transform: scale(0);
}

.s-frame-scrollfx .c-apac-frame__blip:after {
  animation-name: apac-pulse;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.c-apac-frame__blip-ewe {
  top: 28%;
  left: 44%;
}

.c-apac-frame__blip-hog {
  color: #8ac539;
  top: 14%;
  left: 51%;
}

.c-apac-frame__blip-elk {
  top: 18%;
  left: 60%;
}

.c-apac-frame__blip-mustang {
  top: 19%;
  left: 44%;
}

.c-apac-frame__blip-doe {
  color: #53a3e5;
  top: 32%;
  left: 35%;
}

.c-apac-frame__blip-ocelot {
  color: #8ac539;
  top: 39%;
  left: 35%;
}

.c-apac-frame__blip-ibex {
  top: 53%;
  left: 36%;
}

.c-apac-frame__blip-gopher {
  top: 51%;
  left: 43%;
}

.c-apac-frame__blip-dugong {
  top: 56%;
  left: 60%;
}

.c-apac-frame__blip-impala {
  top: 73%;
  left: 46%;
}

.c-apac-frame__blip-otter {
  top: 72%;
  left: 56%;
}

.c-apac-frame__blip-camel {
  color: #53a3e5;
  top: 79%;
  left: 46%;
}

.c-apac-frame__blip-pony {
  color: #8ac539;
  top: 79%;
  left: 66%;
}

.c-apac-frame__blip-hyena {
  color: #53a3e5;
  top: 88%;
  left: 81%;
}

.s-frame-scrollfx .c-apac-frame__blip:first-child {
  transition-delay: .1s;
}

.s-frame-scrollfx .c-apac-frame__blip:first-child:after {
  animation-delay: .166667s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(2) {
  transition-delay: .2s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(2):after {
  animation-delay: .333333s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(3) {
  transition-delay: .3s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(3):after {
  animation-delay: .5s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(4) {
  transition-delay: .4s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(4):after {
  animation-delay: .666667s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(5) {
  transition-delay: .5s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(5):after {
  animation-delay: .833333s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(6) {
  transition-delay: .6s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(6):after {
  animation-delay: 1s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(7) {
  transition-delay: .7s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(7):after {
  animation-delay: 1.16667s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(8) {
  transition-delay: .8s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(8):after {
  animation-delay: 1.33333s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(9) {
  transition-delay: .9s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(9):after {
  animation-delay: 1.5s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(10) {
  transition-delay: 1s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(10):after {
  animation-delay: 1.66667s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(11) {
  transition-delay: 1.1s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(11):after {
  animation-delay: 1.83333s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(12) {
  transition-delay: 1.2s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(12):after {
  animation-delay: 2s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(13) {
  transition-delay: 1.3s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(13):after {
  animation-delay: 2.16667s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(14) {
  transition-delay: 1.4s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(14):after {
  animation-delay: 2.33333s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(15) {
  transition-delay: 1.5s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(15):after {
  animation-delay: 2.5s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(16) {
  transition-delay: 1.6s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(16):after {
  animation-delay: 2.66667s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(17) {
  transition-delay: 1.7s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(17):after {
  animation-delay: 2.83333s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(18) {
  transition-delay: 1.8s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(18):after {
  animation-delay: 3s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(19) {
  transition-delay: 1.9s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(19):after {
  animation-delay: 3.16667s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(20) {
  transition-delay: 2s;
}

.s-frame-scrollfx .c-apac-frame__blip:nth-child(20):after {
  animation-delay: 3.33333s;
}

.c-apac-frame__ui {
  font-size: 23vw;
  transition: transform 3s cubic-bezier(.075, .82, .165, 1), opacity .4s;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 32%, 0)translateZ(100px);
}

.js .c-apac-frame__ui {
  opacity: 0;
  transform: translate3d(-50%, 50%, 0)translateZ(100px);
}

.s-frame-scrollfx .c-apac-frame__ui {
  opacity: 1;
  transition-delay: .4s;
  transform: translate3d(-50%, 32%, 0)translateZ(100px);
}

@media (width >= 37.5em) {
  .c-apac-frame__ui {
    font-size: 10vw;
    bottom: 18%;
    left: -.2em;
    transform: translateZ(100px);
  }

  .js .c-apac-frame__ui {
    transform: translate3d(0, 50%, 0)translateZ(100px);
  }

  .s-frame-scrollfx .c-apac-frame__ui {
    transform: translate3d(0, 0, 0)translateZ(100px);
  }
}

@media (width >= 140.625em) {
  .c-apac-frame__ui {
    font-size: 9rem;
    left: -.7em;
  }
}

.c-apac-frame__ui__key {
  background-color: #fff;
  border-radius: 2px;
  padding: 1.3em 1.7em;
  font-size: .1em;
  overflow: hidden;
}

.c-apac-frame__ui__key__detail {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.c-apac-frame__ui__key__detail:not(:last-child) {
  margin-bottom: .4em;
}

.c-apac-frame__ui__key__bullet {
  border-radius: 50%;
  flex-shrink: 0;
  flex-basis: auto;
  width: 1em;
  height: 1em;
  position: relative;
  overflow: hidden;
}

.c-apac-frame__ui__key__bullet--full {
  background-color: #8ac539;
  border: 2px solid #7fb534;
}

.c-apac-frame__ui__key__bullet--standard {
  background-color: #53a3e5;
  border: 2px solid #419ae2;
}

.c-apac-frame__ui__key__text {
  white-space: nowrap;
  border-radius: 9px;
  flex: 1 0 auto;
  margin-left: 1em;
}

.c-apac-frame__screen {
  width: 100%;
  max-width: 100%;
  padding-bottom: 80%;
  transition: transform 5s cubic-bezier(.075, .82, .165, 1), opacity .5s linear;
  position: relative;
}

.js .c-apac-frame__screen {
  opacity: 0;
  transform: translate3d(0, 20%, 0)perspective(25rem)rotateX(0)rotateY(0);
}

.s-frame-scrollfx .c-apac-frame__screen {
  opacity: 1;
  transform: translate3d(0, 0, 0)perspective(25rem)rotateX(0)rotateY(0);
}

@media (width >= 37.5em) {
  .s-frame-scrollfx .c-apac-frame__screen {
    transform: perspective(25rem)translate3d(0, 0, 0)rotateX(-5deg)rotateY(10deg);
  }
}

@media (width >= 37.5em) and (width <= 140.563em) {
  .c-apac-frame__screen {
    left: 5%;
  }
}

.c-apac-frame__screen svg {
  width: 120%;
  height: 120%;
  position: absolute;
  top: -21%;
  left: -28%;
}

.c-apac-frame__content {
  background-color: #fcfcfc;
  border: 1px solid #fff;
  border-radius: 9px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 0 10rem .03em #eee3;
}

@media (width >= 37.5em) {
  .c-apac-frame__content {
    border: 0;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
  }

  .c-apac-frame__content:after {
    content: "";
    opacity: .1;
    z-index: 5;
    background: linear-gradient(135deg, #eee0 0%, #fff 30%, #51505a 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.c-apac-frame__shadow {
  opacity: .5;
  width: 96%;
  height: 0;
  margin-left: -48%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: perspective(25rem)rotateX(40deg);
  box-shadow: 0 0 .5rem .1rem #252328;
}

@media (width >= 37.5em) {
  .c-apac-frame__shadow {
    width: 98%;
    margin-left: 1%;
    left: auto;
    transform: rotate(-.5deg);
  }
}

.c-section-descr {
  transition: opacity 1s;
}

.js .c-section-descr {
  opacity: 0;
}

.c-section-descr.s-descr-scrollfx {
  opacity: 1;
}

.c-insights-dash {
  width: 10em;
  margin-left: auto;
  margin-right: auto;
  font-size: 8vw;
  position: relative;
}

@media (width >= 75em) {
  .c-insights-dash {
    font-size: 7vw;
  }
}

@media (width >= 140.625em) {
  .c-insights-dash {
    font-size: 7rem;
  }
}

.c-insights-dash__main-img {
  z-index: 1;
  width: 100%;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
}

.js .c-insights-dash__main-img {
  transform: translate3d(0, .25em, 0);
}

.s-data-section-scrollfx .c-insights-dash__main-img {
  transform: translate3d(0, 0, 0);
}

.c-insights-dash__dashboards {
  z-index: 2;
  width: 1.4em;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: 1.15em;
  left: -.098em;
}

.js .c-insights-dash__dashboards {
  transform: translate3d(0, .6em, 0);
}

.s-data-section-scrollfx .c-insights-dash__dashboards {
  transform: translate3d(0, 0, 0);
}

.c-insights-dash__unique {
  z-index: 2;
  width: 1.9em;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: 1.8em;
  left: 2.75em;
}

.js .c-insights-dash__unique {
  transform: translate3d(0, .6em, 0);
}

.s-data-section-scrollfx .c-insights-dash__unique {
  transform: translate3d(0, 0, 0);
}

.c-insights-dash__card {
  z-index: 2;
  width: 1.6em;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  bottom: -.4em;
  right: -.23em;
}

.js .c-insights-dash__card {
  transform: translate3d(0, .6em, 0);
}

.s-data-section-scrollfx .c-insights-dash__card {
  transform: translate3d(0, 0, 0);
}

.c-insights-signal {
  width: 5em;
  font-size: 11vw;
  position: relative;
}

@media (width <= 56.1875em) {
  .c-insights-signal {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (width >= 56.25em) {
  .c-insights-signal {
    font-size: 7vw;
  }
}

@media (width >= 140.625em) {
  .c-insights-signal {
    font-size: 7.6rem;
  }
}

.c-insights-signal__email__bg {
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
}

.js .c-insights-signal__email__bg {
  transform: translate3d(0, 1.2em, 0);
}

.s-data-section-scrollfx .c-insights-signal__email__bg {
  transform: translate3d(0, 0, 0);
}

.c-insights-signal__email__shirt {
  width: 2.75em;
  max-width: none;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  bottom: .2em;
  right: -.75em;
}

.js .c-insights-signal__email__shirt {
  transform: translate3d(0, 1.7em, 0);
}

.s-data-section-scrollfx .c-insights-signal__email__shirt {
  transform: translate3d(0, 0, 0);
}

.c-insights-signal__email__shirt__detection {
  width: 1em;
  height: .9em;
  position: absolute;
  top: .82em;
  left: .85em;
}

.c-insights-signal__email__shirt__detection:after, .c-insights-signal__email__shirt__detection:before {
  content: "";
  border-radius: 2px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-insights-signal__email__shirt__detection:before {
  border: 2px solid #e60016;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1), opacity .5s linear;
}

.js .c-insights-signal__email__shirt__detection:before {
  opacity: 0;
  transform: scale(2);
}

.c-insights-signal__email__shirt.s-data-section-scrollfx .c-insights-signal__email__shirt__detection:before {
  opacity: 1;
  transform: scale(1);
}

.c-insights-signal__email__shirt__detection:after {
  background-color: #e60016;
  transition: opacity .2s linear .3s;
}

.js .c-insights-signal__email__shirt__detection:after {
  opacity: 0;
}

.c-insights-signal__email__shirt.s-data-section-scrollfx .c-insights-signal__email__shirt__detection:after, .no-js .c-insights-signal__email__shirt__detection:after {
  opacity: .2;
}

.c-insights-signal__email__icon {
  width: .5em;
  max-width: none;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: -.1em;
  left: -.25em;
}

.js .c-insights-signal__email__icon {
  transform: translate3d(0, 1.3em, 0);
}

.s-data-section-scrollfx .c-insights-signal__email__icon {
  transform: translate3d(0, 0, 0);
}

.c-insights-signal__email__stats {
  width: 5.4em;
  max-width: none;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: 1.5em;
  left: -.2em;
}

.js .c-insights-signal__email__stats {
  transform: translate3d(0, 1.7em, 0);
}

.s-data-section-scrollfx .c-insights-signal__email__stats {
  transform: translate3d(0, 0, 0);
}

.c-insights-signal__email__isabella {
  width: 4.45em;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: 4.25em;
  left: .26em;
}

.js .c-insights-signal__email__isabella {
  transform: translate3d(0, 1.4em, 0);
}

.s-data-section-scrollfx .c-insights-signal__email__isabella {
  transform: translate3d(0, 0, 0);
}

.c-insights-signal__email__aboogie {
  width: 4.45em;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: 5.05em;
  left: .26em;
}

.js .c-insights-signal__email__aboogie {
  transform: translate3d(0, 1.4em, 0);
}

.s-data-section-scrollfx .c-insights-signal__email__aboogie {
  transform: translate3d(0, 0, 0);
}

.c-insights-bolt {
  width: 5em;
  margin-left: auto;
  margin-right: auto;
  font-size: 12vw;
  position: relative;
}

@media (width >= 56.25em) {
  .c-insights-bolt {
    font-size: 6.5vw;
  }
}

@media (width >= 75em) {
  .c-insights-bolt {
    font-size: 8vw;
  }
}

@media (width >= 140.625em) {
  .c-insights-bolt {
    font-size: 9rem;
  }
}

.c-insights-bolt__shot {
  z-index: 3;
  width: 2.5em;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  bottom: -.2em;
  left: -1em;
}

.js .c-insights-bolt__shot {
  transform: translate3d(0, 1.6em, 0);
}

.s-data-section-scrollfx .c-insights-bolt__shot {
  transform: translate3d(0, 0, 0);
}

.c-insights-bolt__shot__detection {
  width: .23em;
  height: .16em;
  position: absolute;
  top: .79em;
  left: .86em;
}

.c-insights-bolt__shot__detection:after, .c-insights-bolt__shot__detection:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-insights-bolt__shot__detection:before {
  border: 2px solid #e60016;
  border-radius: 2px;
  transition: opacity .4s linear .5s, transform 1s cubic-bezier(.075, .82, .165, 1) .5s;
}

.js .c-insights-bolt__shot__detection:before {
  opacity: 0;
  transform: scale(3);
}

.c-insights-bolt__shot.s-data-section-scrollfx .c-insights-bolt__shot__detection:before {
  opacity: 1;
  transform: scale(1);
}

.c-insights-bolt__shot__detection:after {
  background-color: #e60016;
  border-radius: 2px;
  transition: opacity .3s linear .8s;
}

.js .c-insights-bolt__shot__detection:after {
  opacity: 0;
}

.c-insights-bolt__shot.s-data-section-scrollfx .c-insights-bolt__shot__detection:after, .no-js .c-insights-bolt__shot__detection:after {
  opacity: .4;
}

.c-insights-bolt__app__bg {
  z-index: 1;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
}

.js .c-insights-bolt__app__bg {
  transform: translate3d(0, 1em, 0);
}

.s-data-section-scrollfx .c-insights-bolt__app__bg {
  transform: translate3d(0, 0, 0);
}

.c-insights-bolt__app__overview {
  z-index: 2;
  width: 5.2em;
  transition: transform 2s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: .8em;
  left: .49em;
}

.js .c-insights-bolt__app__overview {
  transform: translate3d(0, 1.3em, 0);
}

.s-data-section-scrollfx .c-insights-bolt__app__overview {
  transform: translate3d(0, 0, 0);
}

.c-insights-bolt__app__overview__volume {
  z-index: 5;
  width: 1.7em;
  transition: transform .5s cubic-bezier(.075, .82, .165, 1) .5s, opacity .3s linear .5s;
  position: absolute;
  top: .51em;
  left: 1.78em;
}

.js .c-insights-bolt__app__overview__volume {
  opacity: 0;
  transform-origin: bottom;
  transform: translate3d(0, .1em, 0)scale(.5);
}

.s-data-section-scrollfx .c-insights-bolt__app__overview__volume {
  opacity: 1;
  transform: translate3d(0, 0, 0)scale(1);
}

.c-insights-bolt__app__overview__volume:after {
  content: "";
  border-top: 8px solid #fff;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  margin-left: -8px;
  position: absolute;
  bottom: -7px;
  left: 50%;
}

.c-insights-bolt__app__overview__label {
  width: .7em;
  position: absolute;
  top: -.16em;
  left: .4em;
}

.c-insights-bolt__app__overview__blip {
  z-index: 3;
  width: .1em;
  height: .1em;
  position: absolute;
  top: 1.08em;
  left: 2.58em;
}

.c-insights-bolt__app__overview__blip:before, .c-insights-bolt__app__overview__blip:after {
  content: "";
  z-index: 2;
  background-color: #3b79cc;
  position: absolute;
}

.c-insights-bolt__app__overview__blip:before {
  border: 2px solid #2c61a8;
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.c-insights-bolt__app__overview__blip:after {
  z-index: 3;
  border-radius: 100%;
  width: 200%;
  height: 200%;
  margin-top: -100%;
  margin-left: -100%;
  top: 50%;
  left: 50%;
}

.s-data-section-scrollfx .c-insights-bolt__app__overview__blip:after, .no-js .c-insights-bolt__app__overview__blip:after {
  animation-name: insights-pulse-simple;
  animation-duration: 5s;
  animation-timing-function: cubic-bezier(.125, .98, .565, 1.17);
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.c-insights-heading {
  transition: opacity 1s linear, transform 2s cubic-bezier(.075, .82, .165, 1);
}

.js .c-insights-heading {
  opacity: 0;
  transform: translate3d(0, 2em, 0);
}

.c-insights-heading.s-data-section-scrollfx, .s-data-section-scrollfx .c-insights-heading {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.c-insights-opening {
  opacity: 0;
  animation-name: insights-text-opening;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(.075, .82, .165, 1);
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}

.c-vizia-six-screen {
  backface-visibility: hidden;
  perspective: 1000px;
  position: relative;
  overflow: hidden;
}

.c-vizia-six-screen:before, .c-vizia-six-screen:after {
  content: "";
  z-index: 2;
  width: 100%;
  position: absolute;
}

.c-vizia-six-screen:before {
  background-image: linear-gradient(#2a282f 0%, #2a282f00 100%);
  height: 40%;
  top: 0;
}

.c-vizia-six-screen:after {
  background-image: linear-gradient(to top, #2a282f 0%, #2a282f00 100%);
  height: 50%;
  bottom: 0;
}

.c-vizia-six-screen__graphic {
  padding-bottom: .3em;
  font-size: 80vw;
  position: relative;
}

@media (width >= 37.5em) {
  .c-vizia-six-screen__graphic {
    font-size: 73vw;
  }
}

@media (width >= 75em) {
  .c-vizia-six-screen__graphic {
    font-size: 68vw;
  }
}

@media (width >= 115.625em) {
  .c-vizia-six-screen__graphic {
    font-size: 61vw;
  }
}

@media (width >= 140.625em) {
  .c-vizia-six-screen__graphic {
    font-size: 75rem;
  }
}

.c-vizia-six-screen__pos {
  position: relative;
}

.c-vizia-six-screen__grid {
  z-index: 2;
  max-width: 1em;
  margin: auto;
  position: relative;
}

.c-vizia-six-screen__grid:before {
  content: "";
  z-index: 2;
  background-image: linear-gradient(#13141700 0%, #131417 50%, #13141700 100%);
  width: .7em;
  height: .4em;
  margin-top: -.2em;
  margin-left: -.35em;
  position: absolute;
  top: 50%;
  left: 50%;
}

.c-vizia-six-screen__grid:after {
  content: "";
  z-index: 1;
  opacity: 0;
  background-image: radial-gradient(#d8d1f933 0%, #d8d1f900 60%);
  width: 1.7em;
  height: .9em;
  margin-top: -.45em;
  margin-left: -.85em;
  transition: opacity .5s linear;
  position: absolute;
  top: 50%;
  left: 50%;
}

.s-vizia-screen-visible .c-vizia-six-screen__grid:after {
  opacity: 1;
}

.c-vizia-six-screen__row {
  z-index: 3;
  flex-direction: row;
  display: flex;
  position: relative;
}

.c-vizia-six-screen__title {
  z-index: 10;
  opacity: 0;
  margin-bottom: 2.75em;
  transition: opacity .2s linear, transform 1.25s cubic-bezier(.075, .82, .165, 1) .1s;
  position: relative;
  transform: translate3d(0, 1em, 0);
}

.c-vizia-six-screen__title.s-vizia-screen-title-visible {
  opacity: 1;
  transition: opacity .8s linear, transform 1.75s cubic-bezier(.075, .82, .165, 1);
  transform: translate3d(0, 0, 0);
}

.c-vizia-six-screen__display {
  background-color: #131417;
  border-radius: 3px;
  flex: 1;
  padding: .007em;
  position: relative;
}

.c-vizia-six-screen__display:after {
  content: "";
  z-index: 1;
  background-image: linear-gradient(135deg, #ffffff05 0%, #fff0 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-vizia-six-screen__display--tilt-forwards {
  transform-origin: bottom;
  transform: rotateX(1deg);
}

.c-vizia-six-screen__display--tilt-backwards {
  transform-origin: top;
  transform: rotateX(-1deg);
}

.c-vizia-six-screen__display__inner {
  z-index: 2;
  background-color: #131417;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.c-vizia-six-screen__display__inner:after, .c-vizia-six-screen__display__inner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}

.c-vizia-six-screen__display__inner:before {
  z-index: 3;
  background-image: radial-gradient(#ffffff0f 0%, #fff0 75%);
  border-radius: 100%;
  width: 85%;
  height: 85%;
}

.c-vizia-six-screen__display__inner:after {
  z-index: 2;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 .15em #0006;
}

.c-vizia-six-screen__display__inner__light-cover {
  z-index: 2;
  opacity: .15;
  background-color: #d8d1f9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-vizia-six-screen__display__inner__reserve {
  padding-top: 56.25%;
}

.c-vizia-six-screen__display__video {
  opacity: .2;
  width: 100%;
  height: 100%;
  transition: opacity .5s linear;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.01);
}

.s-vizia-screen-visible .c-vizia-six-screen__display__video {
  opacity: 1;
}

.c-vizia-six-screen__bg {
  z-index: 1;
  opacity: .4;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: .745em;
  height: .523em;
  font-size: 3.24em;
  transition: opacity .5s linear;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.s-vizia-screen-visible .c-vizia-six-screen__bg {
  opacity: 1;
}

.c-vizia-hero {
  position: relative;
  overflow: hidden;
}

.c-vizia-hero:after {
  content: "";
  z-index: 2;
  background-image: linear-gradient(to top, #2a282f 0%, #2a282f00 100%);
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: -3px;
  left: 0;
}

.c-vizia-hero__pos {
  z-index: 3;
  position: relative;
}

.c-vizia-hero__slider {
  transform-origin: center;
  z-index: 1;
  opacity: .2;
  width: 3.5em;
  height: 2.2em;
  margin-top: -1.1em;
  margin-left: -1.75em;
  font-size: 18rem;
  transition: opacity 3s linear, transform 30s linear;
  position: absolute;
  top: 55%;
  left: 40%;
}

@media (width >= 56.25em) {
  .c-vizia-hero__slider {
    font-size: 27rem;
    left: 50%;
  }
}

@media (width >= 75em) {
  .c-vizia-hero__slider {
    font-size: 34rem;
  }
}

@media (width >= 115.625em) {
  .c-vizia-hero__slider {
    font-size: 37rem;
  }
}

@media (width >= 140.625em) {
  .c-vizia-hero__slider {
    font-size: 38rem;
    left: 53%;
  }
}

@media (width >= 56.25em) {
  .c-vizia-hero__slider {
    opacity: 0;
    transform: scale(1.05);
  }

  .c-vizia-hero__slider.s-hero-slider-active {
    opacity: 1;
    transform: scale(1);
  }
}

.c-vizia-hero__slider__img {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: opacity .75s linear;
  position: absolute;
}

.c-vizia-hero__slider__img.s-hero-slide-active {
  opacity: 1;
}

.c-vizia-hero__slide-control {
  display: flex;
}

@media (width <= 56.1875em) {
  .c-vizia-hero__slide-control {
    display: none;
  }
}

.c-vizia-hero__slide-control__item {
  opacity: .5;
  cursor: pointer;
  width: 1em;
  height: 1em;
  transition: opacity .2s linear;
  display: block;
  position: relative;
}

.c-vizia-hero__slide-control__item:not(:first-child) {
  margin-left: .5em;
}

.c-vizia-hero__slide-control__item:after {
  content: "";
  background-color: #fff;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  transition: transform .35s cubic-bezier(.125, .98, .565, 1.17);
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(.75);
}

.c-vizia-hero__slide-control__item.s-hero-btn-active {
  opacity: 1;
}

.c-vizia-hero__slide-control__item.s-hero-btn-active:after {
  transform: scale(1);
}

.c-vizia-narrative-logo--dell, .c-vizia-narrative-logo--unilever {
  width: 3rem;
}

.c-vizia-narrative-logo--american-airlines {
  width: 8rem;
}

.c-vizia-narrative-img-pos {
  padding-bottom: 11rem;
  position: relative;
}

@media (width >= 37.5em) {
  .c-vizia-narrative-img-pos {
    padding-bottom: 15rem;
  }
}

@media (width >= 56.25em) {
  .c-vizia-narrative-img-pos {
    padding-bottom: 20rem;
  }
}

@media (width >= 75em) {
  .c-vizia-narrative-img-pos {
    padding-bottom: 25rem;
  }
}

@media (width >= 115.625em) {
  .c-vizia-narrative-img-pos {
    padding-bottom: 30rem;
  }
}

@media (width >= 140.625em) {
  .c-vizia-narrative-img-pos {
    padding-bottom: 29rem;
  }
}

.c-vizia-narrative-img {
  opacity: .3;
  z-index: 0;
  width: 35em;
  transition: opacity 1s;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (width >= 37.5em) {
  .c-vizia-narrative-img {
    width: 50em;
  }
}

@media (width >= 56.25em) {
  .c-vizia-narrative-img {
    width: 68em;
  }
}

@media (width >= 75em) {
  .c-vizia-narrative-img {
    width: 82em;
  }
}

@media (width >= 115.625em) {
  .c-vizia-narrative-img {
    width: 90rem;
  }
}

@media (width >= 140.625em) {
  .c-vizia-narrative-img {
    width: 100rem;
  }
}

.s-vizia-narrative-visible .c-vizia-narrative-img {
  opacity: 1;
}

.c-product-nav {
  background-color: #fff;
  border-radius: 2px;
  flex: 1;
  align-content: center;
  padding: 1rem;
  transition: transform 1.5s cubic-bezier(.075, .82, .165, 1);
  display: flex;
  position: relative;
}

@media (width >= 75em) {
  .c-product-nav {
    padding: 1.5rem;
  }
}

.c-product-nav:after, .c-product-nav:before {
  content: "";
  border-radius: 2px;
  width: 100%;
  height: 100%;
  transition: opacity 1.5s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.c-product-nav:before {
  z-index: 1;
  opacity: 1;
  box-shadow: 0 .75px 6px #2523280d, 0 1px 1px #2523280d;
}

.c-product-nav:after {
  z-index: 1;
  opacity: 0;
  box-shadow: 0 5px 18px #25232814, 0 2px 10px #2523280d;
}

.c-product-nav:hover {
  transform: translate3d(0, -.25rem, 0);
}

.c-product-nav:hover:before {
  opacity: 0;
}

.c-product-nav:hover:after {
  opacity: 1;
}

.c-product-nav__content {
  z-index: 2;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.c-product-nav__icon {
  width: 100%;
  max-width: 4rem;
  margin-right: 1rem;
}

@media (width >= 75em) {
  .c-product-nav__icon {
    max-width: 5rem;
    margin-right: 1.5rem;
  }
}

.c-product-nav__details {
  flex: 1;
}

@media (width <= 56.1875em) {
  .c-product-tour__heading {
    display: none;
  }
}

@media (width >= 56.25em) {
  .c-product-tour__heading {
    border-bottom: 1px solid #eee;
    margin-bottom: .7em;
    padding-bottom: .7em;
    font-size: 1.3125rem;
  }

  .c-product-tour--dark .c-product-tour__heading {
    border-bottom: 1px solid #51505a;
  }
}

@media (width >= 115.625em) {
  .c-product-tour__heading {
    font-size: 1.6875rem;
  }
}

.c-product-tour__btn {
  position: relative;
}

@media (width <= 56.1875em) {
  .c-product-tour__btn:not(.s-btn-active) {
    height: 0;
    overflow: hidden;
  }
}

@media (width >= 56.25em) {
  .c-product-tour__btn {
    cursor: pointer;
    padding-top: .75rem;
    padding-bottom: .75rem;
  }
}

@media (width >= 115.625em) {
  .c-product-tour__btn {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.c-product-tour__btn__pos {
  position: relative;
}

.c-product-tour__btn__icon {
  width: 1em;
  height: 1em;
  font-size: 1.5rem;
  position: absolute;
  top: -.16em;
  left: 0;
}

@media (width <= 56.1875em) {
  .c-product-tour__btn__icon {
    display: none;
  }
}

@media (width >= 115.625em) {
  .c-product-tour__btn__icon {
    font-size: 2rem;
  }
}

.c-product-tour__btn__icon__no {
  color: #51505a;
  opacity: .75;
  z-index: 2;
  margin-top: -.5em;
  margin-left: -.5em;
  font-size: .5em;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
}

.c-product-tour--dark .c-product-tour__btn__icon__no {
  color: #c6c7cc;
}

.c-product-tour__btn:hover .c-product-tour__btn__icon__no {
  opacity: 1;
}

.c-product-tour__btn.s-btn-active .c-product-tour__btn__icon__no {
  color: #fff;
  opacity: 1;
}

.c-product-tour__btn__icon__tint, .c-product-tour__btn__icon__tint__color {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-product-tour__btn__icon__tint {
  z-index: 1;
  background-color: #eee;
  border-radius: 100%;
  transition: transform .5s cubic-bezier(.125, .98, .565, 1.17);
  overflow: hidden;
  transform: scale(1);
}

.c-product-tour--dark .c-product-tour__btn__icon__tint {
  background-color: #51505a;
}

.c-product-tour__btn:hover .c-product-tour__btn__icon__tint {
  background-color: #c6c7cc;
  transform: scale(1.2);
}

.c-product-tour--dark .c-product-tour__btn:hover .c-product-tour__btn__icon__tint {
  background-color: #868690;
}

.c-product-tour__btn:active .c-product-tour__btn__icon__tint {
  transform: scale(.8);
}

.c-product-tour__btn.s-btn-active .c-product-tour__btn__icon__tint {
  background-color: #c6c7cc;
  transform: scale(1);
}

.c-product-tour__btn__icon__tint__color {
  opacity: 0;
  transition: opacity .2s linear;
}

.c-product-tour__btn.s-btn-active .c-product-tour__btn__icon__tint__color {
  opacity: 1;
}

.c-product-tour__small-nav {
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  background-image: none;
  outline: none;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.c-product-tour__small-nav::-moz-focus-inner {
  border: 0;
}

.c-product-tour__small-nav:focus {
  outline: 0;
}

@media (width >= 56.25em) {
  .c-product-tour__small-nav {
    display: none;
  }
}

.c-product-tour__small-nav__btn {
  cursor: pointer;
  width: 1.75em;
  height: 1.75em;
  font-size: 1.5em;
  position: relative;
}

.c-product-tour__small-nav__btn__no {
  z-index: 2;
  color: #51505a;
  margin-top: -.5em;
  margin-left: -.5em;
  font-size: .5em;
  position: absolute;
  top: 50%;
  left: 50%;
}

.c-product-tour--dark .c-product-tour__small-nav__btn__no {
  color: #c6c7cc;
}

.c-product-tour__small-nav__btn.s-btn-active .c-product-tour__small-nav__btn__no {
  color: #fff;
}

.c-product-tour__small-nav__btn__tint {
  z-index: 1;
  background-color: #eee;
  border-radius: 100%;
  width: 1em;
  height: 1em;
  margin-top: -.5em;
  margin-left: -.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
}

.c-product-tour--dark .c-product-tour__small-nav__btn__tint {
  background-color: #51505a;
}

.c-product-tour__small-nav__btn.s-btn-active .c-product-tour__small-nav__btn__tint {
  transform: scale(1.3);
}

.c-product-tour__small-nav__btn__tint__color {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.c-product-tour__small-nav__btn.s-btn-active .c-product-tour__small-nav__btn__tint__color {
  opacity: 1;
}

@media (width <= 56.1875em) {
  .c-product-tour__btn__details {
    text-align: center;
  }
}

@media (width >= 56.25em) {
  .c-product-tour__btn__details {
    padding-left: 2.1rem;
  }
}

@media (width >= 115.625em) {
  .c-product-tour__btn__details {
    padding-left: 3rem;
  }
}

.c-product-tour__btn__title {
  font-size: 1.125rem;
  line-height: 1;
  transition: color .2s linear;
}

@media (width >= 56.25em) {
  .c-product-tour__btn:not(.s-btn-active):hover .c-product-tour__btn__title {
    color: #51505a;
  }

  .c-product-tour--dark .c-product-tour__btn:not(.s-btn-active):hover .c-product-tour__btn__title {
    color: #c6c7cc;
  }

  .c-product-tour__btn:not(.s-btn-active) .c-product-tour__btn__title {
    color: #868690;
  }
}

@media (width >= 115.625em) {
  .c-product-tour__btn__title {
    font-size: 1.3125rem;
  }
}

.c-product-tour__btn__desc {
  height: 0;
  margin-top: .65em;
  font-size: .875rem;
  position: relative;
  overflow: hidden;
}

@media (width <= 56.1875em) {
  .c-product-tour__btn__desc {
    max-width: 32em;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (width >= 56.25em) {
  .c-product-tour__btn__desc {
    max-width: 20em;
  }
}

@media (width >= 115.625em) {
  .c-product-tour__btn__desc {
    font-size: 1rem;
  }
}

.c-product-tour__btn.s-btn-active .c-product-tour__btn__desc {
  height: auto;
  overflow: visible;
}

@media (width >= 56.25em) {
  .c-product-tour__btn.s-btn-active .c-product-tour__btn__desc {
    min-height: 3em;
  }
}

.c-product-tour__btn__desc__text {
  opacity: 0;
  transition: transform .5s cubic-bezier(.075, .82, .165, 1), opacity .3s linear;
  transform: translate3d(0, 2em, 0);
}

.c-product-tour__btn.s-btn-active .c-product-tour__btn__desc__text {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.c-product-tour__content {
  font-size: 86vw;
  position: relative;
}

@media (width >= 56.25em) {
  .c-product-tour__content {
    font-size: 40rem;
    left: .05em;
  }
}

@media (width >= 75em) {
  .c-product-tour__content {
    font-size: 50rem;
  }
}

@media (width >= 115.625em) {
  .c-product-tour__content {
    font-size: 50vw;
  }
}

@media (width >= 140.625em) {
  .c-product-tour__content {
    font-size: 50rem;
  }
}

.c-product-tour__content__slides {
  z-index: 2;
  width: 100%;
  padding-top: 63%;
  display: block;
  position: relative;
}

@media (width >= 56.25em) {
  .c-product-tour__content__slides {
    padding-top: 23.5rem;
  }
}

@media (width >= 75em) {
  .c-product-tour__content__slides {
    padding-top: 29rem;
  }
}

@media (width >= 140.625em) {
  .c-product-tour__content__slides {
    padding-top: 32.5rem;
  }
}

.c-product-tour__content__slides__item {
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-product-tour__content__slides__item.s-slide-active {
  visibility: visible;
}

.c-product-tour__content__backboard {
  z-index: 1;
  background-color: #f2f3f5;
  border-radius: 2px;
  width: .95em;
  height: .5em;
  position: absolute;
  top: 0;
  left: 0;
}

.c-product-tour__analytics__app-frame {
  z-index: 2;
  background-color: #fcfcfc;
  width: .95em;
  height: .553em;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: .04em;
  left: .04em;
  transform: translate3d(0, 10%, 0);
  box-shadow: 0 .75px 6px #2523280d, 0 1px 1px #2523280d;
}

.c-product-tour__content__slides__item.s-slide-active .c-product-tour__analytics__app-frame {
  transform: translate3d(0, 0, 0);
}

.c-product-tour__analytics__app-frame--tab {
  top: .058em;
}

.c-product-tour__analytics__tab {
  z-index: 3;
  background-color: #fcfcfc;
  padding: .006em .02em;
  position: absolute;
  top: -.03em;
  left: .032em;
  box-shadow: 0 .75px 6px #2523280d, 0 1px 1px #2523280d;
}

.c-product-tour__analytics__tab:after {
  content: "";
  z-index: 1;
  background-color: #fcfcfc;
  width: 130%;
  height: .02em;
  position: absolute;
  bottom: -.015em;
  left: 50%;
  transform: translateX(-50%);
}

.c-product-tour__analytics__tab__text {
  color: #303138;
  z-index: 2;
  font-size: .016em;
  font-weight: 600;
  position: relative;
}

.c-product-tour__analytics__tab-decor {
  z-index: 1;
  width: .45em;
  height: .05em;
  margin-top: -.025em;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: .06em;
  transform: translate3d(0, .05em, 0);
}

.c-product-tour__content__slides__item.s-slide-active .c-product-tour__analytics__tab-decor {
  transform: translate3d(0, 0, 0);
}

.c-product-tour__analytics__tab-decor svg {
  width: 100%;
  height: 100%;
  position: absolute;
}

.c-product-tour__analytics__tab-decor--analyze {
  left: .205em;
}

.c-product-tour__analytics__tab-decor--segment {
  left: .21em;
}

.c-product-tour__analytics__search-topics {
  background-color: #fff;
  width: .5em;
  height: .285em;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  bottom: -.02em;
  right: .0725em;
  transform: translate3d(0, .04em, 0);
  box-shadow: 0 .75px 6px #2523280d, 0 1px 1px #2523280d;
}

.c-product-tour__content__slides__item.s-slide-active .c-product-tour__analytics__search-topics {
  transform: translate3d(0, 0, 0);
}

.c-product-tour__analytics__segment-mentions {
  background-color: #fff;
  border-radius: 2px;
  width: .35em;
  height: .078em;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: .06em;
  left: .31em;
  transform: translate3d(0, .04em, 0);
  box-shadow: 0 .75px 6px #2523280d, 0 1px 1px #2523280d;
}

.c-product-tour__content__slides__item.s-slide-active .c-product-tour__analytics__segment-mentions {
  transform: translate3d(0, 0, 0);
}

.c-product-tour__analytics__segment-mentions:after {
  content: "";
  border-top: .015em solid #fff;
  border-left: .015em solid #0000;
  border-right: .015em solid #0000;
  margin-left: -.015em;
  position: absolute;
  bottom: -.01em;
  left: 50%;
}

.c-product-tour__analytics__analyze-mention {
  z-index: 2;
  background-color: #fff;
  border-radius: 2px;
  width: .3em;
  height: .175em;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  box-shadow: 0 .75px 6px #2523280d, 0 1px 1px #2523280d;
}

.c-product-tour__content__slides__item.s-slide-active .c-product-tour__analytics__analyze-mention {
  transform: translate3d(0, 0, 0);
}

.c-product-tour__analytics__analyze-mention--east {
  bottom: .22em;
  right: .18em;
  transform: translate3d(0, .1em, 0);
}

.c-product-tour__analytics__analyze-mention--east:after {
  content: "";
  border-top: .015em solid #0000;
  border-bottom: .015em solid #0000;
  border-left: .015em solid #fff;
  position: absolute;
  top: .028em;
  right: -.01em;
}

.c-product-tour__analytics__analyze-mention--west {
  bottom: .27em;
  left: .1em;
  transform: translate3d(0, .04em, 0);
}

.c-product-tour__analytics__analyze-mention--west:after {
  content: "";
  border-top: .015em solid #fff;
  border-left: .015em solid #0000;
  border-right: .015em solid #0000;
  position: absolute;
  bottom: -.009em;
  left: .03em;
}

.c-product-tour__analytics__action-signal {
  z-index: 2;
  background-color: #fcfcfc;
  border-radius: 2px;
  width: .7em;
  height: .546em;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: .04em;
  left: .325em;
  overflow: hidden;
  transform: translate3d(0, .1em, 0);
  box-shadow: 0 .75px 6px #2523280d, 0 1px 1px #2523280d;
}

.c-product-tour__content__slides__item.s-slide-active .c-product-tour__analytics__action-signal {
  transform: translate3d(0, 0, 0);
}

.c-product-tour__analytics__action-mobile {
  z-index: 2;
  background-color: #fcfcfc;
  border-radius: .015em;
  width: .26em;
  height: .475em;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: .08em;
  left: .035em;
  overflow: hidden;
  transform: translate3d(0, .04em, 0);
  box-shadow: 0 .75px 6px #2523280d, 0 1px 1px #2523280d;
}

.c-product-tour__content__slides__item.s-slide-active .c-product-tour__analytics__action-mobile {
  transform: translate3d(0, 0, 0);
}

.c-product-tour__vizia__backboard {
  z-index: 1;
  border-radius: 2px;
  width: .95em;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.c-product-tour__vizia__img {
  display: block;
}

.c-product-tour__vizia__curate-overlay {
  z-index: 2;
  border-radius: 2px;
  width: .96em;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: .07em;
  left: .07em;
  overflow: hidden;
  transform: translate3d(0, .05em, 0);
}

.c-product-tour__content__slides__item.s-slide-active .c-product-tour__vizia__curate-overlay {
  transform: translate3d(0, 0, 0);
}

.c-product-tour__vizia__combine-overlay {
  z-index: 2;
  width: .46em;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: .32em;
  left: .51em;
  transform: translate3d(0, .05em, 0);
}

.c-product-tour__content__slides__item.s-slide-active .c-product-tour__vizia__combine-overlay {
  transform: translate3d(0, 0, 0);
}

.c-product-tour__vizia__connect-overlay {
  z-index: 2;
  width: .45em;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: .39em;
  left: .355em;
  transform: translate3d(0, .05em, 0);
}

.c-product-tour__content__slides__item.s-slide-active .c-product-tour__vizia__connect-overlay {
  transform: translate3d(0, 0, 0);
}

.c-testimonial-video-logo {
  display: none;
}

.c-form-set input:checked + .c-toggle--checkbox {
  background-color: #8ac539;
  border-color: #8ac539;
}

.c-form-set input:checked + .c-toggle--checkbox img, .c-form-set input:checked + .c-toggle--checkbox svg {
  opacity: 1;
}

.essentials-package .choose-plan input[type="radio"]:checked + label {
  background: #f9f9f9;
}

.essentials-package input[type="radio"]:checked + label .circle {
  border-color: #239edb;
  box-shadow: inset 0 0 0 4px #239edb;
}

.essentials-package input[type="radio"]:checked + label .name {
  color: #000;
  font-weight: bold;
}

.number {
  appearance: textfield;
  outline: none;
}

.number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gap-col-3 {
  column-gap: 3rem;
}

.gap-col-2 {
  column-gap: 2rem;
}

.gap-col-1 {
  column-gap: 1rem;
}

.v-sup {
  vertical-align: super;
}

.qty-number {
  opacity: 1 !important;
  color: initial !important;
  background-color: initial !important;
  border-color: #c6c7cc !important;
}

@media (width >= 56.25em) {
  .gap-col-3-md {
    column-gap: 3rem;
  }

  .gap-col-2-md {
    column-gap: 2rem;
  }

  .gap-col-1-md {
    column-gap: 1rem;
  }
}

@media (width >= 37.5em) {
  .gap-col-3-sm {
    column-gap: 3rem;
  }

  .gap-col-2-sm {
    column-gap: 2rem;
  }

  .gap-col-1-sm {
    column-gap: 1rem;
  }
}

/*# sourceMappingURL=module-site--brandwatch.com.css.map */
