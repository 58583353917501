//
// o-audience-cards.scss
//

.o-card-carousel {

  font-size: 1.25vw;

  @media (min-width: bp-width-min(md)) {
    font-size: 1.1vw;
  }

  @media (min-width: bp-width-min(lg)) {
    font-size: 14px;
  }
}

.o-card-carousel__holder {
  width: 59em;

  height: 40em;

  margin: auto;
}


// Column
// ------------------------------------------------------

.o-card-carousel__col {
  transition:
    transform 1s $easing-fastInSlowOut;

  position: absolute;

  top: 0;

  width: 18em;

  margin-bottom: 4em;

  .js .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}

.o-card-carousel__col--1 {
  left: 0;

  .js & {
    transform: translate3d(0,3em,0);
  }

}

.o-card-carousel__col--2 {
  left: 50%;
  margin-left: -9em;

  .js & {
    transform: translate3d(0,6em,0);
  }
}

.o-card-carousel__col--3 {
  right: 0;

  .js & {
    transform: translate3d(0,9em,0);
  }
}


// Card
// ------------------------------------------------------

.o-card-carousel__card {
  transition:
    opacity .3s linear,
    transform .5s $easing-fastInSlowOut;

  position: absolute;

  top: 1.1em;
  left: 0;

  z-index: 1;

  // Hidden by default
  opacity: 0;

  transform: translate3d(0, 2em ,0);

  .o-card-carousel__col--1 & {
    transition-delay: 0s;
  }

  .o-card-carousel__col--2 & {
    transition-delay: .1s;
  }

  .o-card-carousel__col--3 &  {
    transition-delay: .2s;
  }
}

// Visible modifier
.o-card-carousel__card--visible {
  opacity: 1;
  transform: translate3d(0,0,0);
}


// Label
// ------------------------------------------------------

.o-card-carousel__label {
  transition:
    opacity .3s linear,
    transform .5s $easing-bounce;

  display: inline-block;

  position: relative;

  opacity: 0;

  transform: translate3d(0,1em,0);

  padding-left: .8em;
  padding-right: .8em;
  padding-top: .6em;
  padding-bottom: .6em;

  background-color: $color-white;

  box-shadow: map-get($shadow-depth, 1);

  line-height: 1;

  border-radius: $border-radius-ui;

  z-index: 2;

  font-size: 1.5em;

  @media (min-width: bp-width-min(xs)) {
    font-size: 1.1em;
  }

  @media (min-width: bp-width-min(md)) {
    font-size: .8em;
  }

  .o-card-carousel__col--1 & {
    transition-delay: 0s;
  }

  .o-card-carousel__col--2 & {
    transition-delay: .1s;
  }

  .o-card-carousel__col--3 &  {
    transition-delay: .2s;
  }
}

.o-card-carousel__label--visible {
  opacity: 1;
  transform: translate3d(0,0,0);
}


// Navigation
// ------------------------------------------------------

.o-card-carousel__nav {
  margin-bottom: 4em;
}

.o-card-carousel__nav__logo {
  transition: transform 1s $easing-fastInSlowOut;

  position: absolute;

  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%) scale(.8);

  width: 55%;
  display: block;

  path,
  polygon {
    transition: fill .2s linear;
  }

  .o-card-carousel__nav__button:hover & {
    transform: translate(-50%, -50%) scale(1.1);
  }

  .o-card-carousel__nav__button:active & {
    transform: translate(-50%, -50%) scale(.9);
  }

  .o-card-carousel__nav__button--active & {
    transform: translate(-50%, -50%) scale(1.1);
  }

  .o-card-carousel__nav__button--mono & {

    path,
    polygon {
      fill: map-get($color-grey, 5);
    }

  }
}

.o-card-carousel__nav__button {
  position: relative;

  display: block;

  cursor: pointer;

  font-size: 1em;

  width: 9em;

  height: 4em;

  padding: 0;

  &:after,
  &:before {
    content: "";

    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
  }

  &:after {
    transition:
      opacity .1s linear,
      transform 1s $easing-fastInSlowOut;

    border: map-get($border-width, 2) solid #e60016;

    border-radius: map-get($border-radius, 1);

    opacity: 0;

    transform: scale(1.5);
  }

  &:before {
    transition: opacity .3s linear; 

    background-color: #e60016;
    opacity: 0;
  }
}

.o-card-carousel__nav__button--active {
  &:after {
    transform: scale(1);

    opacity: 1;
  }

  &:before {
    transition-delay: .3s;
    opacity: .05;
  }
}