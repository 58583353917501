// 
// o-section-logos.scss
// 
//

.o-section-logos {}


// Logo items
.o-section-logos-item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    
    width: 100%;

    max-width: 10rem;
    max-height: 2rem;

    @media (min-width: bp-width-min(md)) {
      max-width: 10rem;
      max-height: 3rem;
    }

    opacity: 1;

    fill: map-get($color-grey, 5);
  }
}