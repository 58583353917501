// 
// o-sloped-section.scss
// Here be magic numbers. Difficult to get a cross-browser solution with good optical alignment without some of this tweaking
//

.o-sloped-section {
  position: relative;

  box-shadow: map-get($shadow-depth, 5);

  @media (min-width: bp-width-min(sm)) {
    box-shadow: none;
    padding-top: 12vw;
    margin-top: -15vw;
  }

  @media (min-width: bp-width-min(md)) {
    padding-top: 15vw;
  }
}


// Slope
// ------------------------------------------------------

.o-sloped-section__slope {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;

  height: 100%;
  
  @media (min-width: bp-width-min(sm)) {
    height: 14vw;
  }
}

.o-sloped-section__slope__content {

  @media (min-width: bp-width-min(sm)) {
    transition: transform 1s $easing-fastInSlowOut;

    transform: translate3d(0,0,0);

    .s-data-section-scrollfx & {
      transform: translate3d(0,-11%,0);
    }
  }
}

.o-sloped-section__slope__angle {
  position: absolute;

  height: 100%;
  width: 200%;

  @media (min-width: bp-width-min(sm)) {
    transition: transform .75s $easing-fastInSlowOut;

    transform: translate3d(-50%,8vw,0) rotate(7deg);

    height: 1000%;

    left: 50%;

    .s-data-section-scrollfx & {
      transform: translateX(-50%) rotate(0deg);
    }
  }
}


// Section content adjustments
// ------------------------------------------------------

.o-sloped-section__content {

  padding-top: 3rem;

  @media (min-width: bp-width-min(sm)) {
    padding-top: 0;
    margin-top: 3rem;
  }

  @media (min-width: bp-width-min(md)) {
    margin-top: 1.5rem;
  }

  @media (min-width: bp-width-min(lg)) {
    margin-top: -4vw;
  }
}