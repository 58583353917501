// 
// c-vizia-narrative-logo.scss
// 
//

.c-vizia-narrative-logo {
  
}

.c-vizia-narrative-logo--dell {
  width: 3rem;
}

.c-vizia-narrative-logo--unilever {
  width: 3rem;
}

.c-vizia-narrative-logo--american-airlines {
  width: 8rem;
}