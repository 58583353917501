// 
// o-crawl-ticker.scss
// 
//

@use "sass:math";

.o-crawl {
  position: relative;

  overflow: hidden;

  // Minimize impact of ticker on performance below the start tick point
  transform: translate3d(0,0,0);
}


// Number ticker
// ------------------------------------------------------

.o-crawl__ticker {
  

  position: relative;

  opacity: 0;

  transform: translate3d(0,50%,0);

  &.s-crawl__ticker-visible {
    transition: 
      opacity 2s linear,
      transform 3s $easing-fastInSlowOut;
    
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}


// Space reserve
.o-crawl__ticker__reserve {
  position: relative;

  margin-left: auto;
  margin-right: auto;

  height: 1.5em;
  width: 5em;
}

// Positioner
.o-crawl__ticker__pos {
  position: absolute;
  
  top: 0;
  left: 0;

  width: 100%;

  padding-left: .15em;
  padding-bottom: .5em;
  padding-right: .15em;
}

.o-crawl__ticker__number {
  line-height: 1;

  height: 1em;

  span {
   display: inline-block;

   @for $i from 1 through 10 {
    .s-crawl__ticker-visible &:nth-child(#{$i}) {
      transition-delay: #{math.div($i, 20)}s;
    }
  }

    transform: translate3d(0,200%,0);
    opacity: 0;
  }

  .s-crawl__ticker-visible & span {
    transition: 
      transform 2s $easing-fastInSlowOut,
      opacity .5s linear;
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}

// Ticker label
.o-crawl__ticker__label {
  position: absolute;

  bottom: 0;

  left: 0;

  width: 100%;

  border-bottom: 1px solid map-get($color-grey, 5);

  &:after,
  &:before {
    content: "";

    position: absolute;

    bottom: 0;

    background-color: map-get($color-grey, 5);
    height: .2em;
    width: 1px;
  }

  &:after {
    left: 0;
  }

  &:before {
    right: 0;
  }
}

// Inner text
.o-crawl__ticker__label__text {
  position: absolute;

  bottom: 0;

  left: 50%;

  transform: translate(-50%, 50%);

  background-color: #fcfcfd;

  padding-left: 3em;
  padding-right: 3em;
}