// 
// c-apac-frame.scss
// 
//

@use "sass:math";


// Main frame wrapper
.c-apac-frame {
  position: relative;
}


// Language frame
// ------------------------------------------

.c-apac-frame--language {

  $full-color: map-get($color-logo, "green");
  $standard-color: map-get($color-ui, "primary");
  $asia-pac-color: map-get($color-grey, 3);

  &.s-frame-scrollfx .apac-map-full,
  &.s-frame-scrollfx .apac-map-standard {
    transition:
      fill 1s ease .4s,
      stroke 1s ease .4s;
  }

  .apac-map-full {
    fill: $full-color;
    stroke: darken($full-color, 10%);

    .js & {
      fill: $asia-pac-color;
      stroke: darken($asia-pac-color, 5%);
    }
    
    stroke-width: 2px;
  }

  &.s-frame-scrollfx .apac-map-full {
    fill: $full-color;
    stroke: darken($full-color, 10%);
  }

  .apac-map-standard {
    fill: $standard-color;
    stroke: darken($standard-color, 5%);

    .js & {
      fill: $asia-pac-color;
      stroke: darken($asia-pac-color, 5%);
    }

    stroke-width: 2px;
  }

  &.s-frame-scrollfx .apac-map-standard {
    fill: $standard-color;
    stroke: darken($standard-color, 5%);
  }

  .world {
    fill: map-get($color-grey, 2);
  }
}



// Data frame
// ------------------------------------------

.c-apac-frame--data {
  position: relative;

  // Standard world colour
  .world {
    fill: map-get($color-grey, 2);
  }

  // Asia pacific highlighted
  .asia-pac {
    $land-color: map-get($color-grey, 3);

    fill: $land-color;
    stroke: darken($land-color, 10%);
    stroke-width: 2px;
  }
}


// Data frame blips
// ------------------------------------------

.c-apac-frame__blip {
  position: absolute;

  font-size: .4em;

  width: 1em;
  height: 1em;

  border-radius: 50%;

  z-index: 5;

  transition: 
    opacity .3s linear,
    transform .5s ease;

  background-color: currentColor;

  .js & {
    opacity: 0;
    transform: scale(0);
  }

  .s-frame-scrollfx & {
    transform: scale(1);
    opacity: 1;
  }

  &:after {
    content: "";

    position: absolute;

    background-color: currentColor;

    font-size: 10vw;

    @media (min-width: bp-width-min(sm)) {
      font-size: 3vw;
    }

    top: 50%;
    left: 50%;

    margin-left: -.75em;
    margin-top: -.75em;

    width: 1.5em;
    height: 1.5em;

    border-radius: 50%;

    .js & {
      transform: scale(0);
      opacity: 0;
    }

    .s-frame-scrollfx & {

      animation-duration: 3s;
      animation-name: apac-pulse;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
  }
}

.c-apac-frame__blip-ewe {
  left: 44%;
  top: 28%;

  color: map-get($color-logo, "pink");
}

.c-apac-frame__blip-hog {
  left: 51%;
  top: 14%;

  color: map-get($color-logo, "green");
}

.c-apac-frame__blip-elk {
  left: 60%;
  top: 18%;

  color: map-get($color-logo, "darkblue");
}

.c-apac-frame__blip-mustang {
  left: 44%;
  top: 19%;

  color: map-get($color-logo, "yellow");
}

.c-apac-frame__blip-doe {
  left: 35%;
  top: 32%;

  color: map-get($color-ui, "primary");
}

.c-apac-frame__blip-ocelot {
  left: 35%;
  top: 39%;

  color: map-get($color-logo, "green");
}

.c-apac-frame__blip-ibex {
  left: 36%;
  top: 53%;

  color: map-get($color-logo, "purple");  
}

.c-apac-frame__blip-gopher {
  left: 43%;
  top: 51%;

  color: map-get($color-logo, "pink");
}

.c-apac-frame__blip-dugong {
  left: 60%;
  top: 56%;

  color: map-get($color-logo, "yellow");
}

.c-apac-frame__blip-impala {
  left: 46%;
  top: 73%;

  color: map-get($color-logo, "darkorange");    
}

.c-apac-frame__blip-otter {
  left: 56%;
  top: 72%;

  color: map-get($color-logo, "purple");    
}

.c-apac-frame__blip-camel {
  left: 46%;
  top: 79%;

  color: map-get($color-ui, "primary");
}

.c-apac-frame__blip-pony {
  left: 66%;
  top: 79%;

  color: map-get($color-logo, "green");    
}

.c-apac-frame__blip-hyena {
  left: 81%;
  top: 88%;

  color: map-get($color-ui, "primary");
}

@for $i from 1 through 20 {
  .c-apac-frame__blip:nth-child(#{$i}) {

    .s-frame-scrollfx & {
      transition-delay: #{math.div($i, 10)}s;

      &:after {
        animation-delay: #{math.div($i, 6)}s;
      }
    }
    
  }
}


// Language UI overlay
// ------------------------------------------

.c-apac-frame__ui {
  transition: 
    transform 3s $easing-fastInSlowOut,
    opacity .4s ease;

  position: absolute;

  font-size: 23vw;

  left: 50%;
  bottom: 0;

  transform: translate3d(-50%, 32%, 0) translateZ(100px); // Fix z-index issue on IOS

  .js & {
    opacity: 0;
    transform: translate3d(-50%, 50%, 0) translateZ(100px);
  }

  .s-frame-scrollfx & {
    transition-delay: .4s;

    opacity: 1;
    transform: translate3d(-50%, 32%, 0) translateZ(100px);
  }

  @media (min-width: bp-width-min(sm)) {
    transform: translateZ(100px);

    font-size: 10vw;
    left: -.2em;
    bottom: 18%;

    .js & {
      transform: translate3d(0, 50%, 0) translateZ(100px);
    }

    .s-frame-scrollfx & {
      transform: translate3d(0, 0, 0) translateZ(100px);
    }
  }

  @media (min-width: bp-width-min(xxl)) {
    font-size: 9rem;
    left: -.7em;
  }
}

.c-apac-frame__ui__key {
  font-size: .1em;

  background-color: $color-white;

  padding: 1.3em 1.7em;

  border-radius: $border-radius-ui;

  overflow: hidden;
}

.c-apac-frame__ui__key__detail {
  display: flex;
  flex-direction: row;

  align-items: center;

  &:not(:last-child) {
    margin-bottom: .4em;
  }
}

.c-apac-frame__ui__key__bullet {
  position: relative;

  width: 1em;
  height: 1em;

  border-radius: 50%;
  overflow: hidden;

  // Fix for collapse IE11
  flex-shrink: 0;
  flex-basis: auto;
}

.c-apac-frame__ui__key__bullet--full {
  background-color: map-get($color-logo, "green");

  border: map-get($border-width, 2) solid darken(map-get($color-logo, "green"), 4%);
}

.c-apac-frame__ui__key__bullet--standard {
  background-color: map-get($color-ui, "primary");

  border: map-get($border-width, 2) solid darken(map-get($color-ui, "primary"), 4%);
}

.c-apac-frame__ui__key__text {
  flex: 1;
  margin-left: 1em;

  white-space: nowrap;

  border-radius: map-get($border-radius, 3);

  // Fix for collapse IE11
  flex-shrink: 0;
  flex-basis: auto;
}

.c-apac-frame__screen {
  transition: 
    transform 5s $easing-fastInSlowOut,
    opacity .5s linear;

  position: relative;

  width: 100%;

  max-width: 100%;

  padding-bottom: 80%;

  .js & {
    opacity: 0;
    transform: translate3d(0,20%,0) perspective($perspective) rotateX(0) rotateY(0);
  }

  .s-frame-scrollfx & {
    opacity: 1;
    transform: translate3d(0,0,0) perspective($perspective) rotateX(0) rotateY(0);
  }

  @media (min-width: bp-width-min(sm)) {

    .s-frame-scrollfx & {
      transform: perspective($perspective) translate3d(0,0,0) rotateX( -5deg) rotateY( 10deg);
    }
    
  }

  @media (min-width: bp-width-min(sm)) and (max-width: bp-width-max(xxl)) {
    left: 5%;
  }

  svg {
    position: absolute;

    width: 120%;
    height: 120%;

    top: -21%;
    left: -28%;
  }
}

// Content card
// ------------------------------------------------------

.c-apac-frame__content {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: map-get($color-grey, 1);

  border: map-get($border-width, 1) solid $color-white;

  border-radius: map-get($border-radius, 3);

  overflow: hidden;

  box-shadow: 0 0 10rem .03em rgba(map-get($color-grey, 3), .2);

  @media (min-width: bp-width-min(sm)) {
    border-top: map-get($border-width, 2) solid $color-white;
    border-left: map-get($border-width, 2) solid $color-white;

    border-bottom: 0;
    border-right: 0;

    // Gradient
    &:after {
      content: "";

      position: absolute;

      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      background: linear-gradient(135deg, rgba(map-get($color-grey, 3),0) 0%,rgba($color-white,1) 30%,rgba(map-get($color-grey, 6),1) 100%);

      opacity: .1;

      z-index: 5;
    }
  }
}


// Shadow effect
// ------------------------------------------------------

.c-apac-frame__shadow {
  position: absolute;

  bottom: 0;  
  height: 0;

  box-shadow: 0 0 .5rem .1rem $color-shadow;

  transform: perspective($perspective) rotateX( 40deg);

  opacity: .5;
  
  margin-left: -48%;

  width: 96%;
  left: 50%;

  @media (min-width: bp-width-min(sm)) {
    left: auto;
    margin-left: 1%;
    width: 98%;
    transform: rotate(-.5deg);
  }
}