// 
// o-globe-hero.scss
// 
//

.o-globe-hero {
  position: relative;

  overflow: hidden;
}

// Details
// ------------------------------------------------------

.o-globe-hero__details {}


// Globe
// ------------------------------------------------------

.o-globe-hero__globe {
  position: relative;

  
  display: block;
  font-size: 90vw;
  height: 50vh;

  max-height: 9rem;
  min-height: 8rem;

  @media (min-width: bp-width-min(xs)) {
    height: 60vh;
    max-height: 12rem;
    min-height: 11rem;
  }

  @media (min-width: bp-width-min(sm)) {
    max-height: 18rem;
    min-height: 14rem;
  }

  @media (min-width: bp-width-min(md)) {
    max-height: 26rem;
    min-height: 18rem;
  }

  @media (min-width: bp-width-min(lg)) {
    max-height: 22rem;
    min-height: 15rem;
    font-size: 70vw;
  }

  @media (min-width: bp-width-min(xl)) {
    max-height: 26rem;
    min-height: 20rem;
  }

  @media (min-width: bp-width-min(xxl)) {
    max-height: 44rem;
    min-height: 20rem;

    font-size: 90rem;
  }
}


// Titles
// ------------------------------------------------------

.o-globe-hero__sub {


  animation-name: apac-show-element;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: $easing-fastInSlowOut;
  
}

.o-globe-hero__title {
  
  opacity: 0;

  animation-name: apac-show-element;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: .2s;
  animation-timing-function: $easing-fastInSlowOut;
}


// Buttons
// ------------------------------------------------------

.o-globe-hero__primary-btn {

  opacity: 0;
  
  animation-name: apac-show-element;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-delay: .3s;
  animation-timing-function: $easing-fastInSlowOut;
}

.o-globe-hero__secondary-btn {
  
  opacity: 0;
  
  animation-name: apac-show-element;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-delay: .4s;
  animation-timing-function: $easing-fastInSlowOut;
}

// Core globe container
.o-globe-hero__globe__container {
  position: absolute;

  width: 1em;
  height: 1em;

  margin-left: -.5em;

  top: 0;
  left: 50%;
}

// Land overlay asset
.o-globe-hero__globe__land {
  position: absolute;

  width: 100%;
  height: 100%;

  top: -.003em;
  left: 0;
}


// Animation apac-pulses
// ------------------------------------------------------
.o-globe-hero__globe__pulses {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

// apac-pulse elements
.o-globe-hero__globe__pulse {
  position: absolute;

  border-radius: 50%;

  width: .02em;
  height: .02em;
}

.o-globe-hero__globe__pulse__inner {
  position: relative;
  font-size: .02em;

  border-radius: 50%;

  width: .8em;
  height: .8em;

  &:before {
    border: 1px solid map-get($color-grey, 5);
  }

  &:after,
  &:before {
    font-size: 1em;

    content: "";
    position: absolute;

    top: 50%;
    left: 50%;

    width: 1em;
    height: 1em;

    margin-left: -.5em;
    margin-top: -.5em;

    border-radius: 50%;
  }
}


// apac-pulse variants
// ------------------------------------------------------

.o-globe-hero__globe__pulse--fox {
  $color: map-get($color-logo, "green");

  top: .0225em;
  left: .375em;

  transform: perspective($perspective) rotateX( 66deg) rotateY( -14deg);
  
  .o-globe-hero__globe__pulse__inner {
    background-color: $color;

    &:after {
      background-color: $color;

      transform: scale(0);

      font-size: 10em;

      animation-duration: 8s;
      animation-name: apac-pulse;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }

    &:before {
      background-color: $color;

      opacity: .4;

      font-size: 9em;
    }
  }
}

.o-globe-hero__globe__pulse--panther {
  $color: map-get($color-ui, "primary");

  top: .14em;
  left: .29em;

  transform: perspective($perspective) rotateX( 50deg) rotateY( -16deg);

  .o-globe-hero__globe__pulse__inner {
    background-color: $color;

    &:after {
      background-color: $color;

      transform: scale(0);

      font-size: 8em;

      animation-duration: 8s;
      animation-name: apac-pulse;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
      animation-delay: 3s;
    }

    &:before {
      animation-delay: .25s;

      background-color: $color;

      opacity: .4;

      font-size: 8em;
    }
  }
}

.o-globe-hero__globe__pulse--dingo {
  $color: map-get($color-logo, "pink");

  top: .215em;
  left: .4em;

  transform: perspective($perspective) rotateX( 40deg) rotateY( -11deg);

  .o-globe-hero__globe__pulse__inner {
    background-color: $color;

    &:after {

      background-color: $color;

      transform: scale(0);

      font-size: 6em;

      animation-duration: 8s;
      animation-name: apac-pulse;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
      animation-delay: 5s;
    }

    &:before {
      animation-delay: .5s;

      background-color: $color;

      opacity: .4;

      font-size: 6em;
    }
  }
}

// Land SVG
// ------------------------------------------------------

.o-globe-hero__globe__land__img {
  display: block;

  width: 1em;
  height: 1em;
}

// SVG styling
.o-globe-hero__globe__land__img__land {
  $land-color: map-get($color-grey, 3);

  opacity: 1;

  fill: $land-color;
  stroke: darken($land-color, 4%);
  stroke-width: 1.5px;

  // Lock scaling
  vector-effect: non-scaling-stroke;
}

// Body of the globe
.o-globe-hero__globe__body {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border-radius: 50%;

  overflow: hidden;

  background-color: map-get($color-grey, 2);

  box-shadow: inset 0 0 .002em map-get($color-grey, 4), 0 0 2em $color-white;

  // Radial gradient
  &:after {
    content: "";

    position: absolute;

    top: -.45em;
    left: -.25em;

    width: 1.5em;
    height: 1em;

    background: radial-gradient(ellipse at center, map-get($color-grey, 2) 0%, rgba(map-get($color-grey, 3), 0) 60%);

    opacity: .7;
  }
}


// Decorative angle
.o-globe-hero__globe__angle {
  @media (max-width: bp-width-max(xs)) {
    display: none;
  }

  @media (min-width: bp-width-min(sm)) {
    position: absolute;

    bottom: 0;
    left: 0;

    width: 200%;

    border-top: 1px solid map-get($color-grey, 4);

    background-color: $color-white;

    opacity: .4;

    transform: rotate(-3deg);

    height: 100%;
  }
}