//
// c-insights-signal.scss
//
//

.c-insights-signal {
  position: relative;

  width: 5em;

  @media (max-width: bp-width-max(md)) {
    margin-left: auto;
    margin-right: auto;
  }

  font-size: 11vw;

  @media (min-width: bp-width-min(md)) {
    font-size: 7vw;
  }

  @media (min-width: bp-width-min(xxl)) {
    font-size: 7.6rem;
  }
}


// Email
// ------------------------------------------
.c-insights-signal__email {}


// Main background element
// ------------------------------------------

.c-insights-signal__email__bg {
  transition: transform 2s $easing-fastInSlowOut;

  .js & {
    transform: translate3d(0,1.2em,0);
  }

  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}


// Offensive shirt
// ------------------------------------------

.c-insights-signal__email__shirt {
  transition: transform 2s $easing-fastInSlowOut;

  position: absolute;

  max-width: none;

  width: 2.75em;

  right: -.75em;

  bottom: .2em;

  .js & {
    transform: translate3d(0, 1.7em, 0);
  }
  
  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}

.c-insights-signal__email__shirt__detection {
  position: absolute;

  top: .82em;
  left: .85em;

  width: 1em;
  height: .9em;

  &:after,
  &:before {
    content: "";

    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    border-radius: map-get($border-radius, 1);
  }

  &:before {
    transition: 
      transform 1s $easing-fastInSlowOut,
      opacity .5s linear;

    border: map-get($border-width, 2) solid #e60016;

    .js & {
      transform: scale(2);
      opacity: 0;
    }

    .c-insights-signal__email__shirt.s-data-section-scrollfx & {
      transform: scale(1);
      opacity: 1;
    }
  }

  &:after {
    transition: opacity .2s linear .3s;

    background-color: #e60016;

    .js & {
      opacity: 0;
    }

    .c-insights-signal__email__shirt.s-data-section-scrollfx &,
    .no-js & {
      opacity: .2;
    }
  }
}


// Alert icon
// ------------------------------------------

.c-insights-signal__email__icon {
  transition: transform 2s $easing-fastInSlowOut;

  position: absolute;

  max-width: none;

  width: .5em;

  left: -.25em;

  top: -.1em;

  .js & {
    transform: translate3d(0, 1.3em, 0);
  }

  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}

.c-insights-signal__email__icon__inner {}


// Stat overlay
// ------------------------------------------

.c-insights-signal__email__stats {
  transition: transform 2s $easing-fastInSlowOut;

  position: absolute;

  max-width: none;

  width: 5.4em;

  left: -.2em;

  top: 1.5em;

  .js & {
    transform: translate3d(0, 1.7em, 0);
  }

  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}


// Mentions
// ------------------------------------------

.c-insights-signal__email__isabella {
  transition: transform 2s $easing-fastInSlowOut;

  position: absolute;

  width: 4.45em;

  left: .26em;

  top: 4.25em;

  .js & {
    transform: translate3d(0, 1.4em, 0);
  }

  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}

.c-insights-signal__email__aboogie {
  transition: transform 2s $easing-fastInSlowOut;

  position: absolute;

  width: 4.45em;

  left: .26em;

  top: 5.05em;

  .js & {
    transform: translate3d(0, 1.4em, 0);
  }

  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}