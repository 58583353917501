// 
// c-insights-heading.scss
// 
//

.c-insights-heading {
  transition: 
    opacity 1s linear,
    transform 2s $easing-fastInSlowOut;

  .js & {
    opacity: 0;
    transform: translate3d(0,2em,0);
  }

  &.s-data-section-scrollfx,
  .s-data-section-scrollfx & {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}
