//
// c-insights-bolt.scss
//
//

@use "sass:math";


.c-insights-bolt {
  position: relative;

  width: 5em;

  margin-left: auto;
  margin-right: auto;

  font-size: 12vw;

  @media (min-width: bp-width-min(md)) {
    font-size: 6.5vw;
  }

  @media (min-width: bp-width-min(lg)) {
    font-size: 8vw;
  }

  @media (min-width: bp-width-min(xxl)) {
    font-size: 9rem;
  }
}

.c-insights-bolt__shot {
  transition: transform 2s $easing-fastInSlowOut;

  position: absolute;

  bottom: -.2em;
  left: -1em;

  width: 2.5em;

  z-index: 3;

  .js & {
    transform: translate3d(0,1.6em,0);
  }

  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}

.c-insights-bolt__shot__detection {
  position: absolute;

  width: .23em;
  height: .16em;

  top: .79em;
  left: .86em;

  &:after,
  &:before {
    content: "";

    position: absolute;

    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
  }

  &:before {
    transition: 
      opacity .4s linear .5s,
      transform 1s $easing-fastInSlowOut .5s;

    border-radius: map-get($border-radius, 1);

    border: map-get($border-width, 2) solid #e60016;

    .js & {
      opacity: 0;
      transform: scale(3);
    }

    .c-insights-bolt__shot.s-data-section-scrollfx & {
      transform: scale(1);
      opacity: 1;
    }
  }

  &:after {
    transition: opacity .3s linear .8s;

    border-radius: map-get($border-radius, 1);

    background-color: #e60016;
    
    .js & {
      opacity: 0;
    }

    .c-insights-bolt__shot.s-data-section-scrollfx &,
    .no-js & {
      opacity: .4;
    }
  }
}

.c-insights-bolt__app__bg {
  transition: transform 2s $easing-fastInSlowOut;

  z-index: 1;

  .js & {
    transform: translate3d(0,1em,0);
  }

  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}

.c-insights-bolt__app__overview {
  transition: transform 2s $easing-fastInSlowOut;

  position: absolute;

  top: .8em;
  left: .49em;

  width: 5.2em;

  z-index: 2;

  .js & {
    transform: translate3d(0,1.3em,0);
  }

  .s-data-section-scrollfx & {
    transform: translate3d(0,0,0);
  }
}

.c-insights-bolt__app__overview__volume {
  transition: 
    transform .5s $easing-fastInSlowOut .5s,
    opacity .3s linear .5s;

  position: absolute;

  top: .51em;
  left: 1.78em;

  width: 1.7em;

  z-index: 5;

  .js & {
    opacity: 0;
    transform: translate3d(0,.1em,0) scale(.5);
    transform-origin: bottom center;
  }

  .s-data-section-scrollfx & {
    opacity: 1;
    transform: translate3d(0,0,0) scale(1);
  }

  &:after {
    $size: 8px;

    content: "";

    position: absolute;

    left: 50%;
    margin-left: -$size;
    bottom: -($size - 1px);

    border-left: $size solid transparent;
    border-right: $size solid transparent;
    
    border-top: $size solid $color-white;
  }
}

.c-insights-bolt__app__overview__label {
  position: absolute;

  top: -.16em;
  left: .4em;

  width: .7em;
}

.c-insights-bolt__app__overview__blip {
  $color: map-get($color-app, "blue");

  position: absolute;

  left: 2.58em;
  top: 1.08em;

  width: .1em;
  height: .1em;

  z-index: 3;

  &:before,
  &:after {
    content: "";

    position: absolute;

    background-color: $color;

    z-index: 2;
  }

  &:before {
    width: 100%;
    height: 100%;

    border: 2px solid darken($color,10%);

    border-radius: 100%;
  }

  &:after {
    $base: 200%;

    width: $base;
    height: $base;

    left: 50%;
    top: 50%;

    margin-left: math.div(-$base, 2);
    margin-top: math.div(-$base, 2);

    border-radius: 100%;

    z-index: 3;

    .s-data-section-scrollfx &,
    .no-js & {
      animation-name: insights-pulse-simple;
      animation-duration: 5s;
      animation-timing-function: $easing-bounce;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
    }
}
}