//
// c-product-tour.scss
//
//
@use "sass:math";


// Main container
// ------------------------------------------------------

.c-product-tour {}


// Main container
// ------------------------------------------------------

.c-product-tour__heading {
  @media (max-width: bp-width-max(md)) {
    display: none;
  }

  @media (min-width: bp-width-min(md)) {
    font-size: map-get($type-scale-rem, 6);

    padding-bottom: .7em;
    margin-bottom: .7em;

    border-bottom: 1px solid map-get($color-grey, 3);

    .c-product-tour--dark & {
      border-bottom: 1px solid map-get($color-grey, 6);
    }
  }

  @media (min-width: bp-width-min(xl)) {
    font-size: map-get($type-scale-rem, 7);
  }
}


// Pagination button
// ------------------------------------------------------

.c-product-tour__btn {
  position: relative;

  @media (max-width: bp-width-max(md)) {
    &:not(.s-btn-active) {
      height: 0;
      overflow: hidden;
    }
  }

  @media (min-width: bp-width-min(md)) {
    padding-bottom: .75rem;
    padding-top: .75rem;

    cursor: pointer;
  }

  @media (min-width: bp-width-min(xl)) {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

.c-product-tour__btn__pos {
  position: relative;
}

.c-product-tour__btn__icon {
  @media (max-width: bp-width-max(md)) {
    display: none;
  }

  position: absolute;

  top: -.16em;
  left: 0;

  font-size: 1.5rem;
  
  height: 1em;
  width: 1em;

  @media (min-width: bp-width-min(xl)) {
    font-size: 2rem;
  }
}

.c-product-tour__btn__icon__no {
  font-size: .5em;

  position: absolute;

  top: 50%;
  left: 50%;

  margin-top: -.5em;
  margin-left: -.5em;

  color: map-get($color-grey, 6);

  .c-product-tour--dark & {
    color: map-get($color-grey, 4);
  }

  opacity: .75;

  font-weight: map-get($font-weight, 600);

  z-index: 2;

  .c-product-tour__btn:hover & {
    opacity: 1;
  }

  .c-product-tour__btn.s-btn-active & {
    color: $color-white;
    opacity: 1;
  }
}

.c-product-tour__btn__icon__tint,
.c-product-tour__btn__icon__tint__color {
  position: absolute;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
}

.c-product-tour__btn__icon__tint {
  transition: transform .5s $easing-bounce;

  z-index: 1;

  background-color: map-get($color-grey, 3);

  .c-product-tour--dark & {
    background-color: map-get($color-grey, 6);
  }

  border-radius: 100%;

  overflow: hidden;

  transform: scale(1);

  .c-product-tour__btn:hover & {
    background-color: map-get($color-grey, 4);

    transform: scale(1.2);
  }

  .c-product-tour--dark .c-product-tour__btn:hover & {
    background-color: map-get($color-grey, 5);
  }

  .c-product-tour__btn:active & {
    transform: scale(.8);
  }

  .c-product-tour__btn.s-btn-active & {
    background-color: map-get($color-grey, 4);

    transform: scale(1);
  }
}

.c-product-tour__btn__icon__tint__color {
  transition: opacity .2s linear;

  opacity: 0;

  .c-product-tour__btn.s-btn-active & {
    opacity: 1;
  }
}


// Small screen navigation
// ------------------------------------------------------

.c-product-tour__small-nav {
  @include touch-optimize;

  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (min-width: bp-width-min(md)) {
    display: none;
  }
}

.c-product-tour__small-nav__btn {
  position: relative;

  font-size: 1.5em;

  width: 1.75em;
  height: 1.75em;

  cursor: pointer;
}

.c-product-tour__small-nav__btn__no {
  position: absolute;

  font-size: .5em;

  left: 50%;
  top: 50%;

  margin-top: -.5em;
  margin-left: -.5em;

  z-index: 2;

  color: map-get($color-grey, 6);

  .c-product-tour--dark & {
    color: map-get($color-grey, 4);
  }

  .c-product-tour__small-nav__btn.s-btn-active & {
    color: $color-white;
  }
}

.c-product-tour__small-nav__btn__tint {
  $size: 1em;

  position: absolute;

  width: $size;
  height: $size;

  left: 50%;
  top: 50%;

  margin-top: -#{math.div($size, 2)};
  margin-left: -#{math.div($size, 2)};

  overflow: hidden;

  background-color: map-get($color-grey, 3);

  .c-product-tour--dark & {
    background-color: map-get($color-grey, 6);
  }

  border-radius: 100%;

  z-index: 1;

  .c-product-tour__small-nav__btn.s-btn-active & {
    transform: scale(1.3);
  }
}

.c-product-tour__small-nav__btn__tint__color {
  position: absolute;

  width: 100%;
  height: 100%;

  opacity: 0;

  .c-product-tour__small-nav__btn.s-btn-active & {
    opacity: 1;
  }
}


// Details
// ------------------------------------------------------

.c-product-tour__btn__details {
  @media (max-width: bp-width-max(md)) {
    text-align: center;
  }

  @media (min-width: bp-width-min(md)) {
    padding-left: 2.1rem;
  }

  @media (min-width: bp-width-min(xl)) {
    padding-left: 3rem;
  }
}

// Title
.c-product-tour__btn__title {
  transition: color .2s linear;

  line-height: 1;

  font-size: map-get($type-scale-rem, 5);

  @media (min-width: bp-width-min(md)) {
    .c-product-tour__btn:not(.s-btn-active):hover & {
      color: map-get($color-grey, 6);
    }

    .c-product-tour--dark .c-product-tour__btn:not(.s-btn-active):hover & {
      color: map-get($color-grey, 4);
    }

    .c-product-tour__btn:not(.s-btn-active) & {
      color: map-get($color-grey, 5);
    }
  }
  
  @media (min-width: bp-width-min(xl)) {
    font-size: map-get($type-scale-rem, 6);
  }
}

// Desc
.c-product-tour__btn__desc {
  position: relative;

  overflow: hidden;

  height: 0;

  margin-top: .65em;

  font-size: map-get($type-scale-rem, 3);

  @media (max-width: bp-width-max(md)) {
    max-width: 32em;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: bp-width-min(md)) {
    max-width: 20em;
  }

  @media (min-width: bp-width-min(xl)) {
    font-size: map-get($type-scale-rem, 4);
  }

  .c-product-tour__btn.s-btn-active & {
    overflow: visible;
    height: auto;

    // Prevent reflow when less than two lines of text
    @media (min-width: bp-width-min(md)) {
      min-height: 3em;
    }
  }
}

.c-product-tour__btn__desc__text {
  transition:
    transform .5s $easing-fastInSlowOut,
    opacity .3s linear;

  transform: translate3d(0,2em,0);

  opacity: 0;

  .c-product-tour__btn.s-btn-active & {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}


// Main content container
// ------------------------------------------------------

.c-product-tour__content {
  position: relative;

  font-size: 86vw;
  
  @media (min-width: bp-width-min(md)) {
    left: .05em;
    font-size: 40rem;
  }

  @media (min-width: bp-width-min(lg)) {
    font-size: 50rem;
  }

  @media (min-width: bp-width-min(xl)) {
    font-size: 50vw;
  }

  @media (min-width: bp-width-min(xxl)) {
    font-size: 50rem;
  }  
}


// Slide content
// ------------------------------------------------------

.c-product-tour__content__slides {
  position: relative;

  display: block;

  width: 100%;

  z-index: 2;

  padding-top: 63%;

  @media (min-width: bp-width-min(md)) {
    padding-top: 23.5rem;
  }

  @media (min-width: bp-width-min(lg)) {
    padding-top: 29rem;
  }

  @media (min-width: bp-width-min(xxl)) {
    padding-top: 32.5rem;
  }
}

.c-product-tour__content__slides__item {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  visibility: hidden;

  &.s-slide-active {
    visibility: visible;
  }
}


// App backboard
// ------------------------------------------------------

.c-product-tour__content__backboard {
  position: absolute;

  top: 0;
  left: 0;

  width: .95em;

  height: .5em;

  background-color: #f2f3f5;

  border-radius: $border-radius-ui;

  z-index: 1;
}


// Generic app frame
// ------------------------------------------------------

.c-product-tour__analytics__app-frame {
  transition: transform 1s $easing-fastInSlowOut;

  position: absolute;

  top: .04em;
  left: .04em;

  width: .95em;
  height: .553em;

  box-shadow: map-get($shadow-depth, 1);

  transform: translate3d(0,10%,0);

  background-color: #fcfcfc;

  z-index: 2;

  .c-product-tour__content__slides__item.s-slide-active & {
    transform: translate3d(0,0,0);
  }
}

.c-product-tour__analytics__app-frame--tab {
  top: .058em;
}

.c-product-tour__analytics__tab {
  position: absolute;

  top: -.03em;
  left: .032em;

  padding: .006em .02em;

  background-color: #fcfcfc;

  box-shadow: map-get($shadow-depth, 1);

  z-index: 3;

  &:after {
    content: "";

    position: absolute;

    background-color: #fcfcfc;

    width: 130%;
    height: .020em;

    bottom: -.015em;
    left: 50%;

    transform: translateX(-50%);

    z-index: 1;
  }
}

.c-product-tour__analytics__tab__text {
  position: relative;

  font-weight: 600;
  color: map-get($color-grey, 7);
  font-size: .016em;

  z-index: 2;
}


// Decorative (inactive) tabs
// ------------------------------------------------------

.c-product-tour__analytics__tab-decor {
  transition: transform 1s $easing-fastInSlowOut;

  position: absolute;

  top: .06em;

  margin-top: -.025em;

  width: .45em;
  height: .05em;

  z-index: 1;

  transform: translate3d(0,.05em,0);

  .c-product-tour__content__slides__item.s-slide-active & {
    transform: translate3d(0,0,0);
  }

  svg {
    position: absolute;

    width: 100%;
    height: 100%;
  }
}

.c-product-tour__analytics__tab-decor--analyze {
  left: .205em;
}

.c-product-tour__analytics__tab-decor--segment {
  left: .21em;
}

// Search
// ------------------------------------------------------

.c-product-tour__analytics__search-topics {
  transition: transform 1s $easing-fastInSlowOut;

  position: absolute;

  bottom: -.02em;
  right: .0725em;

  width: .5em;

  height: .285em;

  background-color: $color-white;

  box-shadow: map-get($shadow-depth, 1);

  transform: translate3d(0,.04em,0);

  .c-product-tour__content__slides__item.s-slide-active & {
    transform: translate3d(0,0,0);
  }
}


// Segment
// ------------------------------------------------------

.c-product-tour__analytics__segment-mentions {
  transition: transform 1s $easing-fastInSlowOut;

  position: absolute;

  top: .06em;
  left: .310em;

  width: .35em;
  height: .078em;

  background-color: $color-white;

  box-shadow: map-get($shadow-depth, 1);

  border-radius: $border-radius-ui;

  transform: translate3d(0,.04em,0);

  .c-product-tour__content__slides__item.s-slide-active & {
    transform: translate3d(0,0,0);
  }

  &:after {
    $size: .015em;

    content: "";

    position: absolute;

    left: 50%;

    margin-left: -$size;

    bottom: -.010em;

    border-left: $size solid transparent;
    border-right: $size solid transparent;
  
    border-top: $size solid $color-white;
  }
}


// Analyze
// ------------------------------------------------------

.c-product-tour__analytics__analyze-mention {
  transition: transform 1s $easing-fastInSlowOut;

  position: absolute;

  width: .3em;
  height: .175em;

  z-index: 2;

  background-color: $color-white;

  border-radius: $border-radius-ui;

  box-shadow: map-get($shadow-depth, 1);

  .c-product-tour__content__slides__item.s-slide-active & {
    transform: translate3d(0,0,0);
  }
}

.c-product-tour__analytics__analyze-mention--east {
  bottom: .22em;
  right: .18em;

  transform: translate3d(0,.1em,0);

  &:after {
    $size: .015em;

    content: "";

    position: absolute;

    right: -.010em;

    top: .028em;

    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    
    border-left: $size solid $color-white;
  }
}

.c-product-tour__analytics__analyze-mention--west {
  bottom: .27em;
  left: .1em;

  transform: translate3d(0,.04em,0);

  &:after {
    $size: .015em;

    content: "";

    position: absolute;

    left: .03em;

    bottom: -.009em;

    border-left: $size solid transparent;
    border-right: $size solid transparent;
  
    border-top: $size solid $color-white;
  }
}


// Analyze
// ------------------------------------------------------

.c-product-tour__analytics__action-signal {
  transition: transform 1s $easing-fastInSlowOut;

  position: absolute;

  top: .04em;
  left: .325em;

  width: .7em;
  height: .546em;

  box-shadow: map-get($shadow-depth, 1);

  overflow: hidden;

  border-radius: $border-radius-ui;

  transform: translate3d(0,.1em,0);

  background-color: #fcfcfc;

  z-index: 2;

  .c-product-tour__content__slides__item.s-slide-active & {
    transform: translate3d(0,0,0);
  }
}

.c-product-tour__analytics__action-mobile {
  transition: transform 1s $easing-fastInSlowOut;

  position: absolute;

  top: .08em;
  left: .035em;

  width: .26em;
  height: .475em;

  border-radius: .015em;

  overflow: hidden;

  background-color: #fcfcfc;

  box-shadow: map-get($shadow-depth, 1);

  transform: translate3d(0,.04em,0);

  z-index: 2;

  .c-product-tour__content__slides__item.s-slide-active & {
    transform: translate3d(0,0,0);
  }
}


// Vizia
// ------------------------------------------------------
.c-product-tour__vizia__backboard {
  position: absolute;
  top: 0;
  left: 0;
  width: .95em;

  z-index: 1;

  border-radius: $border-radius-ui;

  overflow: hidden;
}

.c-product-tour__vizia__img {
  display: block;
}


.c-product-tour__vizia__curate-overlay {
  transition: transform 1s $easing-fastInSlowOut;

  position: absolute;
  top: .07em;
  left: .07em;
  width: .96em;

  z-index: 2;

  transform: translate3d(0,.05em,0);

  border-radius: $border-radius-ui;

  overflow: hidden;

  .c-product-tour__content__slides__item.s-slide-active & {
    transform: translate3d(0,0,0);
  }
}

.c-product-tour__vizia__combine-overlay {
  transition: transform 1s $easing-fastInSlowOut;

  position: absolute;
  top: .32em;
  left: .51em;
  width: .46em;

  z-index: 2;

  transform: translate3d(0,.05em,0);

  .c-product-tour__content__slides__item.s-slide-active & {
    transform: translate3d(0,0,0);
  }
}

.c-product-tour__vizia__connect-overlay {
  transition: transform 1s $easing-fastInSlowOut;

  position: absolute;
  top: .39em;
  left: .355em;
  width: .45em;

  z-index: 2;

  transform: translate3d(0,.05em,0);

  .c-product-tour__content__slides__item.s-slide-active & {
    transform: translate3d(0,0,0);
  }
}