//
// c-vizia-hero.scss
//
//
@use "sass:math";

// Main container
// ------------------------------------------

.c-vizia-hero {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";

    width: 100%;
    height: 50%;

    position: absolute;

    bottom: -3px;
    left: 0;

    z-index: 2;

    background-image: linear-gradient(to top, rgba(map-get($color-grey, 8), 1) 0%, rgba(map-get($color-grey, 8), 0) 100%);
  }
}

.c-vizia-hero__pos {
  position: relative;

  z-index: 3;
}


// Slider
// ------------------------------------------

.c-vizia-hero__slider {
  transition: opacity 3s linear, transform 30s linear;

  $width: 3.5em;
  $height: 2.2em;

  position: absolute;

  top: 55%;

  left: 40%;

  font-size: 18rem;

  height: $height;
  width: $width;

  margin-left: math.div(-$width, 2);
  margin-top: math.div(-$height, 2);

  transform-origin: center center;

  z-index: 1;

  opacity: .2;

  @media (min-width: bp-width-min('md')) {
    font-size: 27rem;
    left: 50%;
  }

  @media (min-width: bp-width-min('lg')) {
    font-size: 34rem;
  }

  @media (min-width: bp-width-min('xl')) {
    font-size: 37rem;
  }

  @media (min-width: bp-width-min('xxl')) {
    font-size: 38rem;
    left: 53%;
  }

  @media (min-width: bp-width-min('md')) {
    opacity: 0;
    transform: scale(1.05);

    &.s-hero-slider-active {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.c-vizia-hero__slider__img {
  transition: opacity .75s linear;

  position: absolute;

  width: 100%;
  height: 100%;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  opacity: 0;

  &.s-hero-slide-active {
    opacity: 1;
  }
}

.c-vizia-hero__slide-control {
  display: flex;

  @media (max-width: bp-width-max('md')) {
    display: none;
  }
}

.c-vizia-hero__slide-control__item {
  transition: opacity .2s linear;
  position: relative;

  width: 1em;
  height: 1em;

  &:not(:first-child) {
    margin-left: .5em;
  }

  display: block;

  opacity: .5;

  cursor: pointer;

  &:after {
    transition: transform .35s $easing-bounce;
    content: "";

    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: white;

    border-radius: 100%;

    transform: scale(.75);
  }

  &.s-hero-btn-active {
    opacity: 1;

    &:after {
      transform: scale(1);
    }
  }
}