// 
// c-insights-opening.scss
// 
//

.c-insights-opening {
  animation-name: insights-text-opening;
  animation-duration: 2s;
  animation-timing-function: $easing-fastInSlowOut;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;

  opacity: 0;
}