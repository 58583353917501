//
// c-vizia-six-screen.scss
//
//

@use "sass:math";

// Main container
// ------------------------------------------

.c-vizia-six-screen {
  position: relative;
  overflow: hidden;

  backface-visibility: hidden;
  perspective: 1000px;

  &:before,
  &:after {
    content: "";
    position: absolute;

    width: 100%;

    z-index: 2;
  }

  &:before {
    top: 0;

    height: 40%;

    background-image: linear-gradient(to bottom, rgba(map-get($color-grey, 8), 1) 0%, rgba(map-get($color-grey, 8), 0) 100%);
  }

  &:after {
    bottom: 0;

    height: 50%;

    background-image: linear-gradient(to top, rgba(map-get($color-grey, 8), 1) 0%, rgba(map-get($color-grey, 8), 0) 100%);
  }
}

// Actual screen graphic
.c-vizia-six-screen__graphic {
  position: relative;

  padding-bottom: .3em;

  font-size: 80vw;

  @media (min-width: bp-width-min('sm')) {
    font-size: 73vw;
  }

  @media (min-width: bp-width-min('lg')) {
    font-size: 68vw;
  }

  @media (min-width: bp-width-min('xl')) {
    font-size: 61vw;
  }

  // Top our at max container width
  @media (min-width: bp-width-min('xxl')) {
    font-size: $container-width;
  }
}

.c-vizia-six-screen__pos {
  position: relative;
}

.c-vizia-six-screen__grid {
  position: relative;

  max-width: 1em;

  margin: auto;

  z-index: 2;

  &:before {
    $width: .7em;
    $height: .4em;

    content: "";

    position: absolute;

    top: 50%;
    left: 50%;

    margin-top: math.div(-$height, 2);
    margin-left: math.div(-$width, 2);

    background-image: linear-gradient(to bottom, rgba(#131417, 0) 0%, rgba(#131417, 1) 50%, rgba(#131417, 0) 100%);

    width: $width;
    height: $height;

    z-index: 2;
}

  // Purple light cover
  &:after {
    transition: opacity .5s linear;
    $width: 1.7em;
    $height: .9em;

    content: "";

    position: absolute;

    top: 50%;
    left: 50%;

    margin-top: math.div(-$height, 2);
    margin-left: math.div(-$width, 2);

    background-image: radial-gradient(ellipse at center, rgba(#d8d1f9, .2) 0%, rgba(#d8d1f9, 0) 60%);

    width: $width;
    height: $height;

    z-index: 1;

    opacity: 0;

    .s-vizia-screen-visible & {
      opacity: 1;
    }
  }
}

.c-vizia-six-screen__row {
  position: relative;

  display: flex;

  flex-direction: row;

  z-index: 3;
}


// Title
// ------------------------------------------

.c-vizia-six-screen__title {
  transition: 
    opacity .2s linear,
    transform 1.25s $easing-fastInSlowOut .1s;

  position: relative;

  z-index: 10;

  margin-bottom: 2.75em;

  opacity: 0;

  transform: translate3d(0,1em,0);

  &.s-vizia-screen-title-visible {
    transition: 
      opacity .8s linear,
      transform 1.75s $easing-fastInSlowOut;

    opacity: 1;
    transform: translate3d(0,0,0);
  }
}


// Individual displays
// ------------------------------------------

.c-vizia-six-screen__display {
  position: relative;

  flex: 1;
  padding: .007em;

  background-color: #131417;

  border-radius: 3px;

  &:after {
    content: "";

    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    background-image: linear-gradient(135deg, rgba(white, .02) 0%, rgba(white, 0) 100%);

    z-index: 1;
  }
}

.c-vizia-six-screen__display--tilt-forwards {
  transform-origin: center bottom;
  transform: rotateX(1deg);
}

.c-vizia-six-screen__display--tilt-backwards {
  transform-origin: center top;
  transform: rotateX(-1deg);
}

.c-vizia-six-screen__display__inner {
  position: relative;

  overflow: hidden;
  width: 100%;
  background-color: #131417;

  z-index: 2;

  &:after,
  &:before {
    content: "";

    position: absolute;

    top: 0;
    left: 0;
    
  }

  // Decorativee 'Glare'
  &:before {
    z-index: 3;

    border-radius: 100%;

    background-image: radial-gradient(ellipse at center, rgba(white, .06) 0%, rgba(white, 0) 75%);

    height: 85%;
    width: 85%;
  }

  // Screen 'Vignette'
  &:after {
    z-index: 2;

    box-shadow:  inset 0 0 .15em 0 rgba(0,0,0, .4);

    height: 100%;
    width: 100%;
  }
}

.c-vizia-six-screen__display__inner__light-cover {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: #d8d1f9;

  z-index: 2;

  opacity: .15;
}

// Reserve space element to force aspect scaling
.c-vizia-six-screen__display__inner__reserve {
  padding-top: 56.25%; /* 16:9 */
}

// Video element
.c-vizia-six-screen__display__video {
  transition: opacity .5s linear;

  position: absolute;

  top: 0;
  left: 0;

  transform: scale(1.01);

  width: 100%;
  height: 100%;

  opacity: .2;

  .s-vizia-screen-visible & {
    opacity: 1;
  }
}


// Individual displays
// ------------------------------------------

.c-vizia-six-screen__bg {
  transition: opacity .5s linear;
  position: absolute;

  font-size: 3.24em;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  background-repeat: no-repeat;

  background-size: cover;
  background-position: center center;
  width: .745em;
  height: .523em;

  z-index: 1;

  opacity: .4;

  .s-vizia-screen-visible & {
    opacity: 1;
  }
}