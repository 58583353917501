// 
// o-insights-grid.scss
//

@use "sass:math";


.o-insights-grid {
  $base: 100em;

  position: absolute;

  top: 0;
  left: 50%;

  display: flex;
  flex-direction: row;

  transform: translateX(-50%);

  justify-content: center;

  width: 300em;

  font-size: 8px;

  @media (min-width: bp-width-min(sm)) {
    font-size: 9px;
  }

  @media (min-width: bp-width-min(md)) {
    font-size: 10px;
  }

  @media (min-width: bp-width-min(lg)) {
    font-size: 12px;
  }
}

.o-insights-grid__column {
  padding: 1em;
  margin-top: -10em;

  animation-name: insights-card-col-down;

  animation-duration: 30s;
  animation-fill-mode: forwards;
}

.o-insights-grid__item {
  margin-bottom: 2em;

  overflow: hidden;

  border-bottom-left-radius: map-get($border-radius, 2);
  border-bottom-right-radius: map-get($border-radius, 2);

  box-shadow: map-get($shadow-depth, 4);;
}

.o-insights-grid__filler {
  background-color: $color-white;

  box-shadow: map-get($shadow-depth, 4);

  margin-bottom: 2em;
}

.o-insights-grid__filler--tall {
  height: 38em;
}

.o-insights-grid__filler--medium {
  height: 23em;
}

.o-insights-grid__filler--small {
  height: 8em;
}

.o-insights-grid__filler--1 {
  height: 10em;
}

.o-insights-grid__filler--2 {
  height: 20em;
}

.o-insights-grid__filler--3 {
  height: 30em;
}

.o-insights-grid__filler--4 {
  height: 40em;
}

.o-insights-grid__filler--5 {
  height: 50em;
}

.o-insights-grid__fade {
  animation-name: insights-card-fade-in;
  animation-duration: 1s;

  animation-fill-mode: forwards;

  opacity: 0;
}

@for $i from 0 to 11 {
  .o-insights-grid__column:nth-child(#{$i}) {

    $col_delay: math.div($i, 5);

    @for $j from 0 to 10 {
      $item_delay: math.div($j, 10);

      .o-insights-grid__fade:nth-child(#{$j}) {
        animation-delay: #{$col_delay + $item_delay}s;
      }
    }
  }
}