// 
// c-logo-bubbles.scss
// 
//

@use "sass:math";


// Collection container
.c-logo-bubbles {
  position: relative;

  width: 1em;
  height: 1em;

  font-size: 84vw;

  top: -.15em;
  left: -.05em;

  @media (min-width: bp-width-min(sm)) {
    top: -.05em;
    font-size: 38vw;
  }

  @media (min-width: bp-width-min(xxl)) {
    font-size: 40rem;
  }
}


// Base item
// ------------------------------------------------------

.c-logo-bubbles__item {
  transition: 
    transform 3s $easing-fastInSlowOut 0s,
    opacity 1s ease 0s;

  position: absolute;

  border-radius: 50%;

  overflow: hidden;

  background-color: map-get($color-grey, 2);

  width: .5em;
  height: .5em;

  border: map-get($border-width, 1) solid map-get($color-grey, 4);

  .js & {
    transform: scale(.5);
    opacity: 0;
  }

  .s-logo-bubbles-scrollfx & {
    opacity: 1;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 60%;
    height: 60%;

    transform: translate(-50%, -50%);
  }

  .apac-logo-fill {
    fill: map-get($color-grey, 6);
  }
}


// Apply delay cascade
// ------------------------------------------------------

@for $i from 1 through 10 {
  .s-logo-bubbles-scrollfx .c-logo-bubbles__item--#{$i} {
    transition-delay: #{math.div((10 - $i), 8)}s;
  }
}


// Bubble variants
// ------------------------------------------------------

.c-logo-bubbles__item--1 {
  .s-logo-bubbles-scrollfx & {
    transform: scale(.95);
  }

  top: .04em;

  right: 0;

  background-color: map-get($color-ui, "primary");

  border: 0;

  .apac-logo-fill {
    fill: #fff;
  }
}

.c-logo-bubbles__item--2 {
  .s-logo-bubbles-scrollfx & {
    transform: scale(.78);
  }

  bottom: 0;
  right: -.1em;
}

.c-logo-bubbles__item--3 {
  .s-logo-bubbles-scrollfx & {
    transform: scale(.65);
  }

  background-color: map-get($color-logo, "green");

  border: 0;

  .apac-logo-fill {
    fill: #fff;
  }

  left: 0;
  bottom: 0;
}

.c-logo-bubbles__item--4 {
  .s-logo-bubbles-scrollfx & {
    transform: scale(.5);
  }

  background-color: map-get($color-logo, "pink");

  .apac-logo-fill {
    fill: #fff;
  }

  border: 0;

  right: .21em;
  bottom: -.22em;
}

.c-logo-bubbles__item--5 {
  .s-logo-bubbles-scrollfx & {
    transform: scale(.4);
  }


  right: .22em;
  bottom: .08em;
}

.c-logo-bubbles__item--6 {
  .s-logo-bubbles-scrollfx & {
    transform: scale(.29);
  }

  right: .3em;
  bottom: .27em;
}

.c-logo-bubbles__item--7 {
  .s-logo-bubbles-scrollfx & {
    transform: scale(.22);
  }

  left: 0.04em;
  top: .26em;
}

.c-logo-bubbles__item--8 {
  .s-logo-bubbles-scrollfx & {
    transform: scale(.19);
  }

  left: 0.13em;
  top: .09em;
}

.c-logo-bubbles__item--9 {
  .s-logo-bubbles-scrollfx & {
    transform: scale(.14);
  }

  left: 0.01em;
  top: .13em;
}

.c-logo-bubbles__item--10 {
  .s-logo-bubbles-scrollfx & {
    transform: scale(.13);
  }

  left: 0.02em;
  top: .01em;
}