// 
// c-landing-foot.scss
// 
//

.c-landing-foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


// Aux nav
// ------------------------------------------------------

.c-landing-foot__aux {
  flex: 1;
}

.c-landing-foot__aux__list {
  font-size: 1em;

  display: flex;

  justify-content: flex-start;

  flex: 1;

  list-style: none;

  padding-left: 0;

  margin-left: -.8em;
  margin-top: -.8em;
  margin-bottom: -.8em;

  @media (min-width: bp-width-min(xs)) {
    margin-left: -1em;
    margin-top: -1em;
    margin-bottom: -1em;
  }
}

.c-landing-foot__aux__link {
  transition: color .2s linear;

  display: block;

  line-height: 1;

  padding: .8em;

  @media (min-width: bp-width-min(xs)) {
    padding: 1em;
  }

  color: map-get($color-grey, 6);

  &:hover {
    color: $color-white;
  }

  &:active {
    background-color: transparent;
  }
}


// Social nav
// ------------------------------------------------------

.c-landing-foot__social {}

.c-landing-foot__social__list {
  font-size: 1.1em;

  display: flex;

  justify-content: flex-start;

  flex: 1;

  list-style: none;

  padding-left: 0;

  margin-right: -.5em;
  margin-top: -.5em;
  margin-bottom: -.5em;

  @media (min-width: bp-width-min(xs)) {
    font-size: 1.3em;

    margin-right: -.6em;
    margin-top: -.6em;
    margin-bottom: -.6em;
  }
}

.c-landing-foot__social__link {
  transition: color .2s linear;
  
  display: block;

  padding: .5em;

  @media (min-width: bp-width-min(xs)) {
    padding: .6em;
  }

  color: map-get($color-grey, 6);

  &:hover {
    color: $color-white;
  }

  .c-icon {
    display: block;
  }
}

.c-landing-foot__social__icon {
  display: block;
}