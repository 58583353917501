// 
// c-vizia-narrative-img.scss
// 
//

.c-vizia-narrative-img-pos {
  position: relative;

  padding-bottom: 11rem;

  @media (min-width: bp-width-min(sm)) {
    padding-bottom: 15rem;
  }

  @media (min-width: bp-width-min(md)) {
    padding-bottom: 20rem;
  }

  @media (min-width: bp-width-min(lg)) {
    padding-bottom: 25rem;
  }

  @media (min-width: bp-width-min(xl)) {
    padding-bottom: 30rem;
  }

  @media (min-width: bp-width-min(xxl)) {
    padding-bottom: 29rem;
  }
}

.c-vizia-narrative-img {
  transition: 
    opacity 1s ease;

  position: absolute;

  top: 0;
  left: 50%;

  transform: translateX(-50%);

  display: block;

  width: 35em;

  @media (min-width: bp-width-min(sm)) {
    width: 50em;
  }

  @media (min-width: bp-width-min(md)) {
    width: 68em;
  }

  @media (min-width: bp-width-min(lg)) {
    width: 82em;
  }

  @media (min-width: bp-width-min(xl)) {
    width: 90rem;
  }

  @media (min-width: bp-width-min(xxl)) {
    width: 100rem;
  }

  opacity: .3;

  z-index: 0;

  .s-vizia-narrative-visible & {
    opacity: 1;
  }
}