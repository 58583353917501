/* SKYLINE CUSTOM CSS */
.c-form-set input:checked + .c-toggle--checkbox {
  background-color: #8ac539;
  border-color: #8ac539;
}

.c-form-set input:checked + .c-toggle--checkbox img,
.c-form-set input:checked + .c-toggle--checkbox svg {
  opacity: 1;
}

.essentials-package .choose-plan input[type='radio']:checked + label {
  background: #F9F9F9;
}

.essentials-package input[type='radio']:checked + label .circle {
  border-color: #239EDB;
  box-shadow: inset 0 0 0 4px #239EDB;
}

.essentials-package input[type='radio']:checked + label .name {
  font-weight: bold;
  color: #000;
}

.number {
  appearance: textfield;
  -webkit-appearance: textfield;
  outline: none;
}

.number::-webkit-outer-spin-button,
.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gap-col-3 {
  column-gap: 3rem;
}

.gap-col-2 {
  column-gap: 2rem;
}

.gap-col-1 {
  column-gap: 1rem;
}

.v-sup {
  vertical-align: super;
}

.qty-number {
  opacity: 1 !important;
  color: initial !important;
  background-color: initial !important;
  border-color: #c6c7cc !important;
}

@media (min-width:56.25em) {
  .gap-col-3-md {
      column-gap: 3rem;
  }

  .gap-col-2-md {
      column-gap: 2rem;
  }

  .gap-col-1-md {
      column-gap: 1rem;
  }
}

@media (min-width:37.5em) {
  .gap-col-3-sm {
      column-gap: 3rem;
  }

  .gap-col-2-sm {
      column-gap: 2rem;
  }

  .gap-col-1-sm {
      column-gap: 1rem;
  }
}