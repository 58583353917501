// 
// c-section-descr.scss
// 
//

.c-section-descr {

  transition: opacity 1s ease;

  .js & {
    opacity: 0;
  }

  &.s-descr-scrollfx {
    opacity: 1;
  }
}