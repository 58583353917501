//
// animations.scss
//
//

.u-show-scroll {
  transition:
    transform 1.2s $easing-fastInSlowOut,
    opacity .3s linear;

  opacity: 0;
  transform: translate3d(0,30%,0);

  .s-data-section-scrollfx & {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}