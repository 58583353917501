// Import core tooling
// ------------------------------------------

@import '../../core/css/core-tooling';

@media (max-width: bp-width-max(xs)) {
  .x-non-desktop-home-heading *,
  .x-platform-hero-heading {
    font-size: 16vw !important;
  }

  .is-es-site .x-non-desktop-home-heading *,
  .is-es-site .x-platform-hero-heading {
    font-size: 14.5vw !important;
  }

  .is-fr-site .x-non-desktop-home-heading *,
  .is-fr-site .x-platform-hero-heading {
    font-size: 13vw !important;
  }

  .x-non-desktop-home-heading * {
    line-height: 0.88 !important;
  }
}

.x-new-markets-block {
  @media (min-width: bp-width-min(lg)) {
    margin-top: -9rem;
  }
}

.x-home-hero-controls {
  bottom: 24px;
  left: 50%;
}

.x-home-results-controls {
  bottom: -16rem;

  @media (min-width: bp-width-min(md)) {
    bottom: -2.4rem;
    left: 2.5rem;
  }

  @media (min-width: bp-width-min(lg)) {
    left: 50%;
    bottom: -3rem;
  }
}

.x-home-results-controls-arrows {
  bottom: -8rem;

  @media (min-width: bp-width-min(md)) {
    bottom: -3rem;
  }
}


// TODO: Begin stuff from legacy product-pages module.
//       This could be removed if it was rebuilt with
//       atomic/tachyon css and templates.


// Base
// ------------------------------------------

@import 'base/utilities';
@import 'base/animations';


// Objects
// ------------------------------------------

// APAC
@import 'objects/apac/o-globe-hero';
@import 'objects/apac/o-sloped-section';
@import 'objects/apac/o-crawl';
@import 'objects/apac/o-section-logos';

// Image Insights
@import 'objects/image-insights/o-insights-card';
@import 'objects/image-insights/o-insights-grid';
@import 'objects/image-insights/o-audience-cards';
@import 'objects/image-insights/o-card-carousel';


// Components
// ------------------------------------------

// APAC Components
@import 'components/apac/c-logo-bubbles';
@import 'components/apac/c-landing-foot';
@import 'components/apac/c-apac-frame';
@import 'components/apac/c-section-descr';

// Image Insights
@import 'components/image-insights/c-insights-dash';
@import 'components/image-insights/c-insights-signal';
@import 'components/image-insights/c-insights-bolt';
@import 'components/image-insights/c-insights-heading';
@import 'components/image-insights/c-insights-opening';

// Vizia
@import 'components/vizia/c-vizia-six-screen';
@import 'components/vizia/c-vizia-hero';
@import 'components/vizia/c-vizia-narrative-logo';
@import 'components/vizia/c-vizia-narrative-img';

// Common between pages
@import 'components/common/c-product-nav';
@import 'components/common/c-product-tour';
@import 'components/common/c-testimonial-cycler-video';

// Buy Essentials
@import 'components/buy-essentials/c-form';

